import './index.scss';

import * as H from 'history';

import { DashboardData, DeviceDataEntry, GroupedByDateDeviceDataEntry, GroupedByIdDeviceDataEntry } from '../../models';

import { BiArrowBack } from 'react-icons/bi';
import CoolerMap from '../../map';
import DatabaseManager from "../../../service"
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import { DateRangePicker } from 'rsuite';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Period from '../../../utils/period';
import React from 'react';
import Routes from '../../../utils/routes';
import addDays from 'date-fns/addDays';
import moment from "moment";
import pureknob from '../../../libs/knob/pureknob';
import subDays from 'date-fns/subDays';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);
require('highcharts/modules/boost')(Highcharts);

const { combine, before, afterToday } = DateRangePicker;

class ChartOps {

  private options: Highcharts.Options = {
    global: {
      // useUTC: false
    },
    chart: {
      // id: this.id,
      // height: 400,
      // width: '100%',
      type: 'column',
      // stacked: true,
      // foreColor: "#ccc",
      // backgroundColor: 'inherit',
      zoomType: 'x',
      events: {

      }
    },
    // colors: ['#3fb0e4', '#811796'],
    title: {
      text: '',
      align: 'left',
      style: {
        fontSize: '12px',
        fontFamily: 'inherit',
        fontWeight: 'bold',
        color: 'var(--color-edit-txt)'
      }
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen', 'separator', 'downloadPNG',
            'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
          ]
        },
      },
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        //align: 'right',
        //verticalAlign: 'top',
        y: 0
      }
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
          symbol: 'circle'
        },
        lineWidth: 2
      }
    },
    credits: { enabled: false },
    // tooltip: {
    //   // formatter: () => {
    //   //   return 'x: ' + Highcharts.dateFormat('%e %b %y %H:%M:%S', this.x) +
    //   //     ' y: ' + this.y.toFixed(2);
    //   // }
    // },
    time: {
      useUTC: false,
    },
    xAxis: {
      type: 'category',
      allowDecimals: true,
      startOnTick: true,
      // minRange: 1000 * 60 * 60,
      // dateTimeLabelFormats:
      // gridLineWidth: 1,
      // gridLineColor: 'red',
      // lineColor: 'var(--color-edit-txt)',
      // tickColor: 'var(--color-edit-txt)',
      labels: {
        style: {
          color: 'var(--color-edit-txt)',
          font: '11px Trebuchet MS, Verdana, sans-serif'
        }
      },
      title: {
        style: {
          // color: '#333',
          // fontWeight: 'bold',
          fontSize: '12px',
          fontFamily: 'Trebuchet MS, Verdana, sans-serif'
        }
      },
      categories: [],
    },
    yAxis: {
      minorTickInterval: 'auto',
      // lineColor: 'var(--color-edit-txt)',
      lineWidth: 1,
      tickWidth: 1,
      // tickColor: 'var(--color-edit-txt)',
      // gridLineWidth: 1,
      // gridLineColor: 'red',
      labels: {
        style: {
          color: 'var(--color-edit-txt)',
          font: '11px Trebuchet MS, Verdana, sans-serif'
        }
      },
      title: {
        style: {
          // color: '#333',
          // fontWeight: 'bold',
          fontSize: '12px',
          fontFamily: 'Trebuchet MS, Verdana, sans-serif'
        },
        text: 'Data entries count',
      }
    },
    series: []
  };

  public get getOptions(): Highcharts.Options {
    return this.options;
  }
}

type DashbordProps = {
  history: H.History;
  current_page: string;
  user_id: string;
  show: boolean;
}

type DashboardState = {
  dashboard_loading: boolean,
  initial_loading: boolean,
  all_devices_count: number,
  active_devices_count: number,
  //
  dashboard_data: DashboardData | null,
  chart_options: Highcharts.Options;
  //
  range_display_date: string, _r_d_d: boolean,
  current_display_date: string, _c_d_d: boolean,
  selected_date: DateRange,
  show_modal: boolean,
  last_data_date: number,
  //
  dashboard_modal_ref: React.RefObject<HTMLDivElement>,
}

export default class Dashboard extends React.PureComponent<DashbordProps, DashboardState> {

  private temp_in_knob = pureknob.createKnob(75, 75);
  private temp_out_knob = pureknob.createKnob(75, 75);
  private light_knob = pureknob.createKnob(75, 75);
  private humidity_knob = pureknob.createKnob(75, 75);
  private didMount: boolean = false;
  private fetch_runnable: any | null;
  private databaseManager: DatabaseManager = new DatabaseManager();
  private dashboard_ref: React.RefObject<HTMLDivElement> = React.createRef();
  private _chart_options = new ChartOps().getOptions;

  constructor(props: any) {
    super(props);

    if (this._chart_options.title) this._chart_options.title.text = 'Daily Entries';
    // this._chart_options.colors = ['#3f3e82', '#1bd111', '#ff0000'];
    if (this._chart_options.chart && this._chart_options.chart.events) {
      this._chart_options.chart.events.load = () => this.onChartLoad();
      this._chart_options.chart.events.render = () => this.onChartRendered();
      this._chart_options.chart.events.redraw = () => this.onChartRedraw();
    }
    this._chart_options.series = [
      // {
      //   name: "Inside",
      //   type: "column",
      //   turboThreshold: 10000,
      //   data: []
      // },
      // {
      //   name: "Outside",
      //   type: "column",
      //   turboThreshold: 10000,
      //   data: []
      // }
    ];

    this.state = {
      dashboard_loading: false,
      initial_loading: true,
      all_devices_count: 0,
      active_devices_count: 0,
      //
      dashboard_data: null,
      chart_options: this._chart_options,
      //
      range_display_date: "", _r_d_d: false,
      current_display_date: "", _c_d_d: false,
      selected_date: [Period.getPreviousSevenDaysItsFirstDay(), Period.getTodayEndTime()],
      show_modal: false,
      last_data_date: 0,
      //
      dashboard_modal_ref: React.createRef(),
    };

    this.temp_in_knob.setProperty('readonly', true);
    this.temp_in_knob.setProperty('angleStart', -0.75 * Math.PI);
    this.temp_in_knob.setProperty('angleEnd', 0.75 * Math.PI);
    this.temp_in_knob.setProperty('colorBG', '#eeeeee9d');
    this.temp_in_knob.setProperty('colorFG', '#0391f0');
    this.temp_in_knob.setProperty('trackWidth', 0.4);
    this.temp_in_knob.setProperty('valMin', -10.0);
    this.temp_in_knob.setProperty('valMax', 40.0);

    this.temp_out_knob.setProperty('readonly', true);
    this.temp_out_knob.setProperty('angleStart', -0.75 * Math.PI);
    this.temp_out_knob.setProperty('angleEnd', 0.75 * Math.PI);
    this.temp_out_knob.setProperty('colorBG', '#eeeeee9d');
    this.temp_out_knob.setProperty('colorFG', '#0391f0');
    this.temp_out_knob.setProperty('trackWidth', 0.4);
    this.temp_out_knob.setProperty('valMin', -10.0);
    this.temp_out_knob.setProperty('valMax', 40.0);

    this.light_knob.setProperty('readonly', true);
    this.light_knob.setProperty('angleStart', -0.75 * Math.PI);
    this.light_knob.setProperty('angleEnd', 0.75 * Math.PI);
    this.light_knob.setProperty('colorBG', '#eeeeee9d');
    this.light_knob.setProperty('colorFG', '#0391f0');
    this.light_knob.setProperty('trackWidth', 0.4);
    this.light_knob.setProperty('valMin', 0.00);
    this.light_knob.setProperty('valMax', 100.00);

    this.humidity_knob.setProperty('readonly', true);
    this.humidity_knob.setProperty('angleStart', -0.75 * Math.PI);
    this.humidity_knob.setProperty('angleEnd', 0.75 * Math.PI);
    this.humidity_knob.setProperty('colorBG', '#eeeeee9d');
    this.humidity_knob.setProperty('colorFG', '#0391f0');
    this.humidity_knob.setProperty('trackWidth', 0.4);
    this.humidity_knob.setProperty('valMin', 0.00);
    this.humidity_knob.setProperty('valMax', 100.0);
  }

  componentDidMount() {
    this.didMount = true;
    this.resetData([Period.getTodayStartTime(), Period.getTodayEndTime()], false);
    this.listenForInputFocus();
    const temp_in_knob_view = document.getElementById('temp-in-knob');
    if (temp_in_knob_view) {
      temp_in_knob_view.appendChild(this.temp_in_knob.node());
    }
    const temp_out_knob_view = document.getElementById('temp-out-knob');
    if (temp_out_knob_view) {
      temp_out_knob_view.appendChild(this.temp_out_knob.node());
    }
    const light_knob_view = document.getElementById('light-knob');
    if (light_knob_view) {
      light_knob_view.appendChild(this.light_knob.node());
    }
    const humi_knob_view = document.getElementById('humi-knob');
    if (humi_knob_view) {
      humi_knob_view.appendChild(this.humidity_knob.node());
    }
    this.updateGauge();
    this.setDefaultChartData(this.state.all_devices_count);
  }

  componentWillUnmount() {
    this.didMount = false;
    if (this.fetch_runnable) {
      clearInterval(this.fetch_runnable);
    }
  }

  private updateGauge() {
    this.temp_in_knob.setValue(
      (this.state.dashboard_data &&
        this.state.dashboard_data.averages &&
        this.state.dashboard_data.averages.temperature &&
        this.state.dashboard_data.averages.temperature.inside) ?
        this.state.dashboard_data.averages.temperature.inside : 0.0);
    this.temp_out_knob.setValue(
      (this.state.dashboard_data &&
        this.state.dashboard_data.averages &&
        this.state.dashboard_data.averages.temperature &&
        this.state.dashboard_data.averages.temperature.outside) ?
        this.state.dashboard_data.averages.temperature.outside : 0.0);
    this.temp_in_knob.setProperty('colorFG',
      (this.state.dashboard_data &&
        this.state.dashboard_data.averages &&
        this.state.dashboard_data.averages.temperature &&
        this.state.dashboard_data.averages.temperature.inside > 15) ?
        '#f003ad' : '#0391f0');
    this.temp_out_knob.setProperty('colorFG',
      ((this.state.dashboard_data &&
        this.state.dashboard_data.averages &&
        this.state.dashboard_data.averages.temperature &&
        this.state.dashboard_data.averages.temperature.outside < 15) ||
        (this.state.dashboard_data &&
          this.state.dashboard_data.averages &&
          this.state.dashboard_data.averages.temperature &&
          this.state.dashboard_data.averages.temperature.outside > 35)) ?
        '#f003ad' : '#0391f0');
    this.light_knob.setValue(
      (this.state.dashboard_data &&
        this.state.dashboard_data.averages &&
        this.state.dashboard_data.averages.light) ?
        this.state.dashboard_data.averages.light : 0.0);
    this.humidity_knob.setValue(
      (this.state.dashboard_data &&
        this.state.dashboard_data.averages &&
        this.state.dashboard_data.averages.humidity) ?
        this.state.dashboard_data.averages.humidity : 0.0);
    this.light_knob.setProperty('colorFG',
      (this.state.dashboard_data &&
        this.state.dashboard_data.averages &&
        this.state.dashboard_data.averages.light >= 75) ?
        '#0391f0' : '#f003ad');
    this.humidity_knob.setProperty('colorFG',
      (this.state.dashboard_data &&
        this.state.dashboard_data.averages &&
        this.state.dashboard_data.averages.humidity > 50) ?
        '#f003ad' : '#0391f0');
  }

  private setDefaultChartData(all_devices_count: number) {
    if (!this.didMount) {
      return;
    }

    const categories = []; // days_in_selected_date_array
    const series: { name: string, data: number[] }[] = []; // series_array

    const selected_date = this.state.selected_date;
    // find how many days are in the selected date using moment.js
    const days_in_selected_date = moment(selected_date[1]).diff(moment(selected_date[0]), 'days');
    // console.log('selected_date: ', selected_date);
    // console.log('days_in_selected_date', days_in_selected_date);
    if (days_in_selected_date > 0) {
      // get each day its strt datetime of the selected date
      for (let i = 0; i < days_in_selected_date + 1; i++) {
        const found_day = moment(selected_date[0]).add(i, 'days').format('ll');
        categories.push(found_day);
        // console.log('found_day', found_day);
      }
    } else {
      const found_day = moment(selected_date[0]).format('ll');
      categories.push(found_day);
    }
    if (all_devices_count > 0) {
      for (let i = 0; i < all_devices_count; i++) {
        series.push({
          name: `Device ${i + 1}`,
          data: [],
        });
        for (let d = 0; d < categories.length; d++) {
          series[i].data.push(0);
        }
      }
    } else {
      series.push({
        name: 'No Devices',
        data: [],
      });
    }

    (this._chart_options.xAxis as any).categories = categories;
    this._chart_options.series = series as any;
    this._chart_options.series && this._chart_options.series.map((series_item) => {
      if (series_item.type === 'area' ||
        series_item.type === 'line' ||
        series_item.type === 'bar' ||
        series_item.type === 'column') {
        return series_item.turboThreshold = series_item.data ? series_item.data.length : 0;
      }
      else return series_item;
    });
    this.setState({ chart_options: this._chart_options })
    window.setTimeout(() => {
      // Highcharts && Highcharts.charts && Highcharts.charts.forEach(chart => {
      //   if (chart) chart.redraw();
      // });
      this.forceUpdate();
    }, this.state.chart_options.series ? this.state.chart_options.series.length : 1000);

    // console.log ('categories: ', categories);
    // console.log ('series: ', series);
  }

  private listenForInputFocus() {
    setTimeout(() => {
      if (!this.didMount) {
        return;
      }
      if (this.dashboard_ref.current) {
        // find first input element
        const input_element = this.dashboard_ref.current.querySelector('input') as HTMLInputElement; // deviceItem.querySelector('input[class="rs-picker-toggle-textbox"]') as HTMLInputElement;
        if (input_element) {
          // detect on focus
          input_element.addEventListener('focus', () => {
            if (!this.didMount) {
              return;
            }
            // console.log('input_element.focus()');
            this.setState({ show_modal: true });
            this.forceUpdate();

            setTimeout(() => {
              if (!this.didMount) {
                return;
              }
              // find element by class name
              const buttons_container = document.querySelector('.rs-picker-toolbar') as HTMLDivElement;
              if (buttons_container) {
                // get all buttons and add event listener for click
                const buttons = buttons_container.querySelectorAll('button');
                if (buttons && buttons.length > 0) {
                  for (let i = 0; i < buttons.length; i++) {
                    if (!this.didMount) {
                      return;
                    }
                    const button = buttons[i] as HTMLButtonElement;
                    if (button) {
                      button.addEventListener('click', () => {
                        if (!this.didMount) {
                          return;
                        }
                        // console.log('button.click()');
                        this.setState({ show_modal: false });
                        this.forceUpdate();
                      });
                    }
                  }
                }
              }
            }, 1000);
          });
        }

        // detect ecscape key for this div 
        document.addEventListener('keydown', (e) => {
          if (!this.didMount) {
            return;
          }
          if (e.keyCode === 27) {
            console.log('this.dashboard_ref.current.keydown()');
            if (this.dashboard_ref.current) {
              if (!this.didMount) {
                return;
              }
              if (this.state.dashboard_modal_ref.current) {
                // perform click
                this.state.dashboard_modal_ref.current.click();
              } else {
                console.log('modal not found');
                this.setState({ show_modal: false });
                this.forceUpdate();
              }
            }
          }
        });
      }
    }, 100);
  }

  private resetData(dateRange: DateRange, fromDatePicker: boolean) {
    if (!this.didMount) return;
    const weeklyDateRange: DateRange = [Period.getPreviousSevenDaysItsFirstDay(), Period.getTodayEndTime()];
    this.setState({
      last_data_date: 0,
      dashboard_loading: false,
      //is_there_data_to_show: true,
      range_display_date: "", _r_d_d: false,
      current_display_date: "", _c_d_d: false,
      // all_devices_count: 0,
      // active_devices_count: 0,
      // avg_temp_in: 0.00,
      // avg_temp_out: 0.00,
      // avg_light: 0.00,
      // avg_humi: 0.00,
      //
      selected_date: fromDatePicker ? dateRange : weeklyDateRange
    });
    this.forceUpdate();

    if (this.fetch_runnable) clearInterval(this.fetch_runnable); // clear previous intervals
    this.databaseManager.cancelRequests();

    setTimeout(() => {
      if (!this.didMount) return;
      if (!this.props.history.location.pathname.startsWith(`${Routes.Dashboard}`)) {
        return;
      }
      this.watchData(this.state.selected_date);
      this.fetch_runnable = setInterval(() => {
        if (Period.isToday(this.state.selected_date[1].valueOf())) {
          this.watchData(this.state.selected_date);
        }
      }, 60000);
    }, 500);
  }

  private watchData(dateRange: DateRange) {
    if (!this.didMount) return;
    if (this.state.dashboard_loading) return;
    if (!this.props.history.location.pathname.startsWith(`${Routes.Dashboard}`)) {
      return;
    }
    if (!Period.isToday(dateRange[1].valueOf())) {
      if (this.fetch_runnable) {
        clearInterval(this.fetch_runnable);
      }
    }
    // console.log('watchData: called: ', dateRange);
    const selected_date: DateRange = dateRange;
    const start_date: Date = selected_date[0] ? selected_date[0] : Period.getTodayStartTime();
    const end_date: Date = selected_date[1] ? selected_date[1] : Period.getTodayEndTime();

    if (this.state.last_data_date === 0) {
      const the_start_date: number = start_date.valueOf();// + 1;
      const the_end_date: number = end_date.valueOf();// + 1;
      this.getData(the_start_date, the_end_date);
    }
    else {
      if (this.state.last_data_date > 0) {
        const last_data_date: number = this.state.last_data_date;// + 1;
        const _next_date: number = end_date.valueOf();// + 1;
        this.getData(last_data_date, _next_date);
      }
    }
  }

  private onRefreshClick() {
    if (!this.didMount || this.state.dashboard_loading) {
        return;
    }
    if (!this.props.history.location.pathname.startsWith(`${Routes.Dashboard}`)) {
        return;
    }
    this.watchData(this.state.selected_date);
  }

  private getData(start_time: number, end_time: number) {
    if (!this.didMount) return;
    if (!this.props.history.location.pathname.startsWith(`${Routes.Dashboard}`)) {
      return;
    }
    if (start_time > 0) {
      this.setState({
        range_display_date: Period.displayRangeDate(start_time, end_time),
        _r_d_d: true,
      });
      this.forceUpdate();
      this.databaseManager.cancelRequests();

      this.setState({ dashboard_loading: true, initial_loading: false });
      this.databaseManager
        .getDashboardData(this.props.user_id, start_time, end_time)
        .then((result: any) => {
          if (!this.didMount) return;
          // console.log("getDashboardData: result: ", result);
          this.setState({ dashboard_loading: false });
          if (result && result.success && result.success.data &&
            result.success.data.length > 0) {
            const dashboard_data: DashboardData = result.success.data[0];
            if (dashboard_data) {
              let active_devices = 0;
              const devices = dashboard_data.devices;
              const devices_data_entries: DeviceDataEntry[] = [];
              if (devices && devices.length > 0) {
                devices.forEach((device) => {
                  if (device) {
                    if (device.last_updated) {
                      const now_time = moment().valueOf();
                      const last_updated_time = moment(device.last_updated).valueOf();
                      if (now_time - last_updated_time < 60000) {
                        active_devices++;
                      }
                    }
                    if (device.data) {
                      if (device.data.daily_entries && device.data.daily_entries.length > 0) {
                        device.data.daily_entries.forEach((entry) => {
                          if (entry) {
                            devices_data_entries.push({
                              id: device.id,
                              name: device.name,
                              total_entries: entry.total ? entry.total : 0,
                              time: entry.time ? entry.time : 0,
                            });
                          }
                        });
                      }
                    }
                  }
                });
              }
              if (devices_data_entries.length > 0) {
                // sort by time asc
                devices_data_entries.sort((a, b) => {
                  if (a.time < b.time) {
                    return -1;
                  }
                  if (a.time > b.time) {
                    return 1;
                  }
                  return 0;
                });
                const grouped_by_date_devices_data_entries: GroupedByDateDeviceDataEntry[] = [];
                const grouped_by_id_devices_data_entries: GroupedByIdDeviceDataEntry[] = [];
                // group by date from time
                devices_data_entries.forEach((entry) => {
                  // use index to find the date
                  let found_date_index = -1;
                  if (grouped_by_date_devices_data_entries.length > 0) {
                    for (let i = 0; i < grouped_by_date_devices_data_entries.length; i++) {
                      if (grouped_by_date_devices_data_entries[i].date === moment(entry.time).format("ll")) {
                        found_date_index = i;
                        break;
                      }
                    }
                  }
                  if (found_date_index === -1) {
                    grouped_by_date_devices_data_entries.push({
                      date: moment(entry.time).format("ll"),
                      // entries: [entry],
                    });
                  }

                  // use index to find the id
                  let found_id_index = -1;
                  if (grouped_by_id_devices_data_entries.length > 0) {
                    for (let i = 0; i < grouped_by_id_devices_data_entries.length; i++) {
                      if (grouped_by_id_devices_data_entries[i].id === entry.id) {
                        found_id_index = i;
                        break;
                      }
                    }
                  }
                  if (found_id_index === -1) {
                    grouped_by_id_devices_data_entries.push({
                      id: entry.id,
                      entries: [entry],
                    });
                  } else {
                    grouped_by_id_devices_data_entries[found_id_index].entries.push(entry);
                  }

                });

                const categories: string[] = []; // x-axis categories
                // const categories_by_datetime: string[] = []; // x-axis categories
                const series: { name: string, data: number[] }[] = []; // y-axis series
                grouped_by_date_devices_data_entries.forEach((gd) => {
                  categories.push(gd.date);
                  // categories_by_datetime.push(moment(gd.date).format("ll"));
                });

                grouped_by_id_devices_data_entries.forEach((gi) => { // gi: grouped_by_id_devices_data_entries
                  series.push({
                    name: gi.entries[0].name,
                    data: [],
                  });
                  const series_data: number[] = [];
                  categories.forEach((date) => {
                    let found_same_date_index = -1;
                    for (let x = 0; x < gi.entries.length; x++) { // data entries
                      if (date === moment(gi.entries[x].time).format("ll")) { // find the same date
                        found_same_date_index = x;
                        break;
                      }
                    }
                    if (found_same_date_index > -1) {
                      series_data.push(gi.entries[found_same_date_index].total_entries); // push the total entries
                    } else {
                      series_data.push(0); // push 0 if not the same date
                    }
                  });
                  series[series.length - 1].data = series_data;
                });

                (this._chart_options.xAxis as any).categories = categories;
                this._chart_options.series = series as any;
                this._chart_options.series && this._chart_options.series.map((series_item) => {
                  if (series_item.type === 'area' ||
                    series_item.type === 'line' ||
                    series_item.type === 'bar' ||
                    series_item.type === 'column') {
                    return series_item.turboThreshold = series_item.data ? series_item.data.length : 0;
                  }
                  else return series_item;
                });
                this.setState({ chart_options: this._chart_options })
                window.setTimeout(() => {
                  // Highcharts && Highcharts.charts && Highcharts.charts.forEach(chart => {
                  //   if (chart) chart.redraw();
                  // });
                  this.forceUpdate();
                }, this.state.chart_options.series ? this.state.chart_options.series.length : 1000);

                console.log ('categories: ', categories);
                console.log ('series: ', series);
              } else {
                this.setDefaultChartData(dashboard_data.total_devices ? dashboard_data.total_devices : 0);
              }
              this.setState({
                active_devices_count: active_devices,
                all_devices_count: dashboard_data.total_devices ? dashboard_data.total_devices : 0,
                dashboard_data: dashboard_data,
              });
            }
          }
          this.setState({
            current_display_date: moment().format("HH:mm:ss"),
            _c_d_d: true,
          });
          this.forceUpdate();
          this.updateGauge();
        }).catch((_error: any) => {
          if (!this.didMount) return;
          this.setState({
            dashboard_loading: false, initial_loading: false,
            current_display_date: moment().format("HH:mm:ss"),
            _c_d_d: true,
          });
        });
    }
  }

  private onChartRendered(): void {
    // console.log('onChartRendered: called:');
  }

  private onChartLoad(): void {
    // console.log('onChartLoad:     called:');
  }

  private onChartRedraw(): void {
    // console.log('onChartRedraw:   called:');
  }

  private clearCharts() {
    this._chart_options.series && this._chart_options.series.map((series_item) => {
      if (series_item.type === 'area' ||
        series_item.type === 'line' ||
        series_item.type === 'bar' ||
        series_item.type === 'column') {
        return series_item.data = [];
      }
      else return series_item;
    });

    this.setState({
      chart_options: this._chart_options,
    });
  }

  private datePicker() {
    const lower_date = new Date();
    lower_date.setFullYear(2021, 8, 1);
    lower_date.setHours(0, 0, 0, 0);

    return (
      <DateRangePicker
        format={"dd-MM-yyyy HH:mm:ss"}
        appearance="subtle"
        placeholder="Select date range"
        character="  to  "
        // size="sm"
        defaultValue={this.state.selected_date}
        cleanable={false}
        oneTap={false}
        open={this.state.show_modal}
        disabledDate={
          combine && combine(before && before(lower_date), afterToday && afterToday())
        }
        style={{
          backgroundColor: "var(--color-div-bg)"
        }}
        ranges={[
          {
            label: 'Yesterday',
            value: [addDays(Period.getDayStartTime(), -1), addDays(Period.getDayEndTime(), -1)]
          },
          {
            label: 'Today',
            value: [Period.getTodayStartTime(), Period.getTodayEndTime()]
          },
          // {
          //   label: 'Tomorrow',
          //   value: [dateFns.addDays(new Date(), 1), dateFns.addDays(new Date(), 1)]
          // },
          {
            label: 'Last 7 days',
            value: [subDays(Period.getDayStartTime(), 6), Period.getDayEndTime()]
          }
        ]}
        onOpen={() => {
          // console.log('date-range-picker: opened');
          this.setState({ show_modal: true });
        }}
        onChange={(date: DateRange | null) => {
          if (date === null) {
            return;
          }
          // console.log('date-range-picker: changed: ', date);
          this.resetData(date, true);
        }}
        onOk={(date: DateRange, event: React.SyntheticEvent<Element, Event>) => {
          if (date === null) {
            return;
          }
          event.preventDefault();
          // console.log('date-range-picker: ok: ', date);
          this.resetData(date, true);
        }}
        onClose={() => {
          this.setState({ show_modal: false });
        }}
      />
    );
  }

  render() {
    return (
      <div className="dashboard-main"
        ref={this.dashboard_ref}
        style={{ display: this.props.show ? 'flex' : 'none' }}>
        {this.state.show_modal &&
          <div className='dashboard-modal'
            ref={this.state.dashboard_modal_ref}
            onClick={() => {
              if (!this.didMount) { return; }
              this.setState({ show_modal: false });
            }} />
        }
        <div className='dashboard-title-lay'>
          <BiArrowBack className='dashboard-back-btn'
            style={{ display: (this.props.history.location.pathname === Routes.Dashboard || this.props.history.location.pathname === Routes.Dashboard + '/') ? 'flex' : 'none' }}
            onClick={(e) => {
              e.preventDefault();
              if (this.props.history.length > 1) {
                this.props.history.goBack();
              } else {
                this.props.history.push(`${Routes.Dashboard}`);
              }
            }} />
          <span className="dashboard-title-text">Dashboard</span>
          <div className='refresh-btn' onClick={() => this.onRefreshClick()}>Refresh</div>
        </div>
        <div className='dashboard-contents-lay'>
          <div className="top-lay">
            <div className="counts-cont">
              <div className="all-count-cont">
                {this.state.initial_loading && <div className='dashboard-detail-item-loader loading-shimmer' />}
                <div className="text-cont">
                  <p className="all-count-title item-title">All Devices</p>
                  <p className="all-count-info item-info">{(this.state.all_devices_count > 1 || this.state.all_devices_count === 0 ? this.state.all_devices_count + " Devices" : this.state.all_devices_count + " Device")}</p>
                </div>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="all-count-icon">
                  <path fill="var(--color-icon)"
                    d="M0,160V48A48,48,0,0,1,48,0H336a48,48,0,0,1,48,48V160H320V72a8,8,0,0,0-8-8H296a8,8,0,0,0-8,8v88Zm320,32V376a8,8,0,0,1-8,8H296a8,8,0,0,1-8-8V192H0V464a48,48,0,0,0,48,48H336a48,48,0,0,0,48-48V192Z"
                    className=""></path>
                </svg>
              </div>
              <div className="active-count-cont">
                {this.state.initial_loading && <div className='dashboard-detail-item-loader loading-shimmer' />}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className={this.state.active_devices_count > 0 ? "active-count-icon actual-active" : "active-count-icon"}>
                  <path fill="var(--color-icon)"
                    d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z"
                    className=""></path>
                </svg>
                {/* <div className="active-count-icon"/> */}
                <div className="text-cont">
                  <p className="active-count-title item-title">Active Devices</p>
                  <p className="active-count-info item-info">{(this.state.active_devices_count > 1 || this.state.active_devices_count === 0 ? this.state.active_devices_count + " Devices" : this.state.active_devices_count + " Device")}</p>
                </div>
              </div>
            </div>
            <div className="time-count-cont">
              {this.state.initial_loading && <div className='dashboard-detail-item-loader loading-shimmer' />}
              <div className="range-display-lay">
                <div className="range-display-cont">
                  {this.datePicker()}
                </div>
              </div>
              <div className="current-display-lay">
                <p className="info">Last Loading:  {this.state._c_d_d ? this.state.current_display_date : "Loading..."}</p>
              </div>
            </div>
          </div>
          <div className="second-lay">
            <div className="left-lay">
              <div className="average-lay">
                <div className="avg-left-cont avg-temp-cont">
                  {this.state.initial_loading && <div className='dashboard-detail-item-loader loading-shimmer' />}
                  <div className='text-title'>Averages ( °C )</div>
                  <div className="temp-in-lay">
                    <div className="temp-in">
                      <div className='text-info'>Temperature Inside</div>
                    </div>
                    <div className="temp-in-knob avg-knob" id="temp-in-knob"></div>
                  </div>
                  <div className="temp-out-lay">
                    <div className="temp-out">
                      <div className='text-info'>Temperature Outside</div>
                    </div>
                    <div className="temp-out-knob avg-knob" id="temp-out-knob"></div>
                  </div>
                </div>
                <div className='avg-right-cont'>
                  {this.state.initial_loading && <div className='dashboard-detail-item-loader loading-shimmer' />}
                  <div className='text-title'>Averages ( % )</div>
                  <div className="avg-light-lay">
                    <div className="avg-light">
                      <div className='text-info'>Light</div>
                    </div>
                    <div className="light-knob avg-knob" id="light-knob"></div>
                  </div>
                  <div className="avg-humi-lay">
                    <div className="avg-humi">
                      <div className='text-info'>Humidity</div>
                    </div>
                    <div className="humi-knob avg-knob" id="humi-knob"></div>
                  </div>
                </div>
              </div>
              <div className='dashboard-chart-lay'>
                {this.state.initial_loading && <div className='dashboard-detail-item-loader loading-shimmer' />}
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'chart'}
                  allowChartUpdate={true}
                  immutable={false}
                  updateArgs={[true, true, false]}
                  containerProps={{ className: 'chart-lay', id: 'dashboard-chart' }}
                  options={this.state.chart_options}
                />
              </div>
            </div>
            <div className='right-lay '>
              {this.state.initial_loading && <div className='device-detail-item-loader loading-shimmer' />}
              <CoolerMap
                is_dashboard={true}
                is_setting={false}
                locations={[]}
                is_location_data_loaded={!this.state.initial_loading}
                location={undefined}
                last_location_updated={0}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}