import './index.scss';

import * as H from 'history';

import { match, withRouter } from 'react-router-dom';

import AuthContext from '../../service/context';
import DatabaseManager from '../../service';
import React from 'react';
import Routes from '../../utils/routes';
import Themer from '../../utils/themer';

type Props = {
  history: H.History,
  match: match,
  location: H.Location,
}

type State = {
  email: string,
  password: string,
  error: any | null,
  isInvalid: boolean,
  isLoading: boolean,
}

class Login extends React.Component<Props, State> {

  private didMount: boolean = false;
  private databaseManager: DatabaseManager;
  static contextType = AuthContext;

  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: '',
      isInvalid: false,
      isLoading: false,
    };

    this.databaseManager = new DatabaseManager();
  }

  componentDidMount() {
    const user_id = sessionStorage.getItem('laibon-ai');
    if (user_id) {
      this.context.setIsAuthenticated(true);
      let { from }: any = this.props.location.state || { from: { pathname: Routes.Dashboard } };
      this.props.history.replace(from);
    }
    this.didMount = true;
    Themer.subscribe();
    document.title = "Login";
  }

  componentWillUnmount() {
    this.didMount = false;
    Themer.unsubscribe();
  }

  onSubmit = (event: any) => {
    event.preventDefault();
    if (!this.didMount) return;
    const { email, password } = this.state;

    this.setState({
      isLoading: true,
      error: null,
    });

    this.databaseManager.loginUser(email, password,
      {
        onResult: (result: any) => {
          if (!this.didMount) return;
          this.setState({ isLoading: false });
          if (result) {
            // console.log('result: ', result);
            if (result.success && result.success.data && result.success.data.length > 0) {
              const name = result.success.data[0].name;
              const user_id = result.success.data[0].id;
              localStorage.setItem('user_name', name);
              localStorage.setItem('user_id', user_id);

              this.context.setIsAuthenticated(true);
              let { from }: any = this.props.location.state || { from: { pathname: Routes.Dashboard } };
              this.props.history.replace(from);
            }
            else {
              this.setState({ error: { message: 'Could not login. Reason: ' + result.error.message } });
            }
          }
          else {
            this.setState({ error: { message: 'Could not login. Reason, User Login Error!' } });
          }
        },
        onError: (err: any) => {
          if (!this.didMount) return;
          // console.log('fetch: error: ', err);
          this.setState({ isLoading: false, error: { message: 'Could not login. Reason, User Login Error!' } });
        }
      });
  };

  onEmailChange = (event: any) => {
    this.setState({ email: event.target.value });

    if (this.state.email.length < 5 || this.state.password.length < 3) {
      this.setState({ isInvalid: true });
    }
    if (this.state.email.length > 5 || this.state.password.length > 3) {
      this.setState({ isInvalid: false });
    }
  };

  onPasswordChange = (event: any) => {
    this.setState({ password: event.target.value });

    if (this.state.email.length < 5 || this.state.password.length < 3) {
      this.setState({ isInvalid: true });
    }
    if (this.state.email.length > 5 || this.state.password.length > 3) {
      this.setState({ isInvalid: false });
    }
  };

  render() {
    return (
      <div className="login-main">
        <span className="app-title">IoT Cooler System</span>
        <span className="login-title">Login</span>
        <form className='form-container' onSubmit={this.onSubmit}>
          <input
            required={true}
            className='email-input input'
            name='email'
            value={this.state.email}
            onChange={this.onEmailChange}
            type='text'
            autoComplete='email'
            placeholder='Email Address' />
          <input
            required={true}
            className='password-input input'
            name='password'
            value={this.state.password}
            onChange={this.onPasswordChange}
            type='password'
            autoComplete='current-password'
            placeholder='Password' />
          <button
            className='login-btn'
            disabled={this.state.isInvalid}
            type='submit'>
            Log In
          </button>
          {this.state.isLoading && <i className="fa-li fa fa-spinner fa-spin loading-icon"></i>}
          {this.state.error && <p className="message-box">{this.state.error.message}</p>}
        </form>
      </div>
    );
  }
}

export default withRouter(Login);