import './index.scss';

import * as H from 'history';

import { Device, DeviceGroup, UserData } from '../models';
import Header, { Footer } from './header';
import { Link, match, withRouter } from 'react-router-dom';

import Account from './account'
import { BiArrowBack } from 'react-icons/bi';
import Dashboard from './dashboard';
import DatabaseManager from '../../service';
import DeviceItem from './device';
import React from 'react';
import { RiDeviceFill } from 'react-icons/ri';
import Routes from '../../utils/routes';
import SideNav from './side-nav';
import Themer from '../../utils/themer';
import WindowLocationHandler from '../../utils/location-handler';

type Props = {
  history: H.History;
  location: H.Location;
  match: match<{}>;
}

type State = {
  user_id: string | null,
  user_name: string | null,
  user_data: UserData | null,
  //
  current_page: string,
  dashboard_showed: boolean,
  account_showed: boolean,

  device_groups: DeviceGroup[],
  devices_loading: boolean,
  error_loading: string | null,
  selected_device_id: string,
  is_fetching: boolean,

  show_modal: boolean,
}

class Main extends React.PureComponent<Props, State> {

  private databaseManager: DatabaseManager;
  private didMount: boolean = false;

  // private device_groups: DeviceGroup[] = [];

  constructor(props: any) {
    super(props);

    this.databaseManager = new DatabaseManager();

    this.state = {
      user_id: null,
      user_name: "",
      user_data: null,
      //
      current_page: this.props.history.location.pathname,
      dashboard_showed: false,
      account_showed: false,
      //
      device_groups: [],
      devices_loading: false,
      error_loading: null,
      selected_device_id: "",
      is_fetching: false,

      show_modal: false,
    }
    this.onLogOutBtnClick = this.onLogOutBtnClick.bind(this);
  }

  componentDidMount() {
    this.didMount = true;

    const user_id = window.localStorage.getItem('user_id');
    const user_name = window.localStorage.getItem('user_name');
    if (!user_id) {
      let { from }: any = this.props.location.state || { from: { pathname: Routes.Login } };
      this.props.history.replace(from);
      return;
    }

    // console.log('user_id: ' + user_id);
    this.setState({ user_id: user_id, user_name: user_name });

    this.onReloadClick();
    WindowLocationHandler.subscribe(path => {
      if (!this.didMount) return;
      this.switchPage(path);
    });
    this.switchPage(this.state.current_page);
    Themer.subscribe();
  }

  componentWillUnmount() {
    this.didMount = false;
    WindowLocationHandler.unsubscribe();
    Themer.unsubscribe();
  }

  private switchPage(path: string) {
    // console.log('switchPage: ' + path);
    this.setState({ show_modal: false, current_page: path });
    if (path === Routes.Devices || path === Routes.Devices + '/') {
      document.title = 'Iot - Devices';
    }
    if (path.startsWith(`${Routes.Devices}/`)) {
      document.title = 'Iot - Device';
      let deviceId = path.replace(Routes.Devices + '/', '');
      if (deviceId.length > 0) {
        if (this.state.device_groups.length > 0) {
          const device_groups = this.state.device_groups;
          for (let i = 0; i < device_groups.length; i++) {
            for (let j = 0; j < device_groups[i].devices.length; j++) {
              const device = device_groups[i].devices[j];
              if (device.id === deviceId) {
                device.shown = true;
                break
              }
            }
          }
          this.setState({ device_groups });
          this.forceUpdate();
        }
      }
    } else if (path.startsWith(Routes.Dashboard)) {
      document.title = 'Iot - Dashboard';
      this.setState({ dashboard_showed: true });
    } else if (path.startsWith(Routes.Account)) {
      document.title = 'Iot - Account';
      this.setState({ account_showed: true });
    } else if (path === Routes.Activities) {
      document.title = 'Iot - Activities';
    } else if (path === Routes.Help) {
      document.title = 'Iot - Help';
    }
    this.forceUpdate();
  }

  private onReloadClick() {
    if (!this.didMount) return;
    const user_id = localStorage.getItem('user_id');
    if (!user_id) return;

    this.setState({ devices_loading: true, error_loading: null });

    // this.device_groups = []; // clear list;
    // this.temp_saved_location = { lat: 0, lng: 0 };
    // this.temp_saved_location_time = 0;

    this.setState({ device_groups: [], });

    this.databaseManager.getUserDevices(user_id, {
      onResult: (result: any) => {
        if (!this.didMount) return;
        this.setState({ devices_loading: false });
        if (result && result.success && result.success.data) {
          const device_groups = this.arrangeDevices(result.success.data.length > 0 && result.success.data[0].devices ? result.success.data[0].devices : [], true);
          this.setState({
            user_data: result.success.data.length > 0 ? result.success.data[0] : null,
            device_groups: device_groups,
            error_loading: result.success.data.length > 0 ? null : "No devices found",
          });
          this.forceUpdate();
        }
      },
      onError: () => {
        if (!this.didMount) return;
        this.setState({
          devices_loading: false,
          error_loading: "Could not load devices\nClick Here To Retry",
        });
      }
    });
  }

  private arrangeDevices(devices: Device[], new_list: boolean): DeviceGroup[] {
    const device_groups: DeviceGroup[] = new_list ? this.state.device_groups : [];
    for (var i = 0; i < devices.length; i++) {
      const device: Device = devices[i];
      device.shown = this.state.current_page.startsWith(`${Routes.Devices}/${device.id}`);
      if (device.location_name) {
        var exist: boolean = false;
        var exist_position: number = -1;
        for (var e = 0; e < device_groups.length; e++) {
          const user_device = device_groups[e];
          if (user_device.location_name === device.location_name) {
            exist = true;
            exist_position = e;
            break;
          }
        }
        if (exist) {
          device_groups[exist_position].devices.push(device);
        }
        else {
          device_groups.push({
            location_name: device.location_name,
            devices: [device],
          });
        }
      }
    }
    return device_groups;
  }

  private onDeviceUpdated(device: Device) {
    // console.log('onDeviceUpdated: ', device);
    if (!this.didMount) return;
    const device_groups = this.state.device_groups;
    let update_position = -1;
    for (let i = 0; i < device_groups.length; i++) {
      for (let j = 0; j < device_groups[i].devices.length; j++) {
        if (device_groups[i].devices[j].id === device.id) {
          update_position = j;
          break;
        }
      }
      if (update_position !== -1) {
        device_groups[i].devices[update_position] = device;
        break;
      }
    }
    const _devices: Device[] = [];
    for (let a = 0; a < device_groups.length; a++) {
      for (let b = 0; b < device_groups[a].devices.length; b++) {
        _devices.push(device_groups[a].devices[b]);
      }
    }
    const user_data = this.state.user_data;
    if (user_data) {
      user_data.devices = _devices;
      this.setState({ user_data });
    }
    this.setState({ device_groups: this.arrangeDevices(_devices, false) });
    this.forceUpdate();
    // console.log('onDeviceUpdated: device_groups: ', this.state.device_groups);
  }

  private onLogOutBtnClick() {
    if (!this.didMount) return;
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_id');
    setTimeout(() => this.props.history.push('/'), 100);
  }

  render() {
    return (
      <div className="main-home-lay">
        <SideNav
          show={this.state.show_modal}
          history={this.props.history}
          current_page={this.state.current_page}
          onLogOutBtnClick={() => this.onLogOutBtnClick()} />
        <div className={this.state.show_modal ? "side-nav-modal active-modal" : 'side-nav-modal'}
          onClick={(e) => {
            e.preventDefault();
            this.setState({ show_modal: false })
          }} />
        <Header
          onNavMenuClick={() => { this.setState({ show_modal: true }) }}
          user_name={this.state.user_name}
        />
        <div className="iot-main-contents" id="main-contents">
          {(this.state.dashboard_showed ||
            this.state.current_page.startsWith(Routes.Dashboard)) &&
            this.state.user_id &&
            <Dashboard
              history={this.props.history}
              current_page={this.state.current_page}
              user_id={this.state.user_id}
              show={this.state.current_page.startsWith(Routes.Dashboard)}
            />
          }
          {(this.state.account_showed ||
            this.state.current_page.startsWith(Routes.Account)) &&
            this.state.user_data &&
            <Account
              history={this.props.history}
              current_page={this.state.current_page}
              user_data={this.state.user_data}
              show={this.state.current_page.startsWith(Routes.Account)}
            />}
          <div className='devices-main-lay'
            style={{ display: this.state.current_page.startsWith(Routes.Devices) ? 'flex' : 'none' }}>
            <div className='devices-list-lay'>
              <div className='devices-list-title-lay'>
                <BiArrowBack className='devices-list-back-btn'
                  style={{ display: (this.state.current_page === Routes.Devices || this.state.current_page === Routes.Devices + '/') ? 'flex' : 'none' }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.props.history.length > 1) {
                      this.props.history.goBack();
                    } else {
                      this.props.history.push(`${Routes.Dashboard}`);
                    }
                  }} />
                <span className="devices-list-title-text">All Devices</span>
              </div>
              <div className='devices-list-contents-lay'>
                {this.state.devices_loading &&
                  <>
                    {/* <i className="fa-li fa fa-spinner fa-spin loading-icon"></i> */}
                    <div className='device-item-loader loading-shimmer' />
                    <div className='device-item-loader loading-shimmer' />
                    <div className='device-item-loader loading-shimmer' />
                    <div className='device-item-loader loading-shimmer' />
                    <div className='device-item-loader loading-shimmer' />
                    <div className='device-item-loader loading-shimmer' />
                    <div className='device-item-loader loading-shimmer' />
                    <div className='device-item-loader loading-shimmer' />
                  </>
                }
                {this.state.error_loading && <p className="message-box" onClick={(e) => { e.preventDefault(); this.onReloadClick(); }}>{this.state.error_loading}</p>}
                {this.state.device_groups.map((user_devices: DeviceGroup, index: number) => {
                  return (
                    <div className='devices-location-cont' key={index}>
                      <div className="devices-location-title-lay">
                        <div className="lines-lay">
                          <div className="vert-2 lines" />
                          <div className="horz lines" />
                        </div>
                        <p className="devices-location-title">
                          {user_devices.location_name}
                        </p>
                      </div>
                      {user_devices.devices.map((device: Device, index: number, array: Device[]) => {
                        return (
                          <div className="device-item" key={device.id}>
                            <div className="lines-lay">
                              <div className="vert-1 lines" />
                              <div className="vert-2 lines" style={{ display: array.length - 1 === index ? 'none' : 'block' }} />
                              <div className="horz lines" />
                            </div>
                            <Link to={Routes.Devices + '/' + device.id} className={
                              (this.state.current_page.startsWith(Routes.Devices + '/' + device.id)) ?
                                "device-item-btn device-item-selected device-" + device.id + "" :
                                "device-item-btn device-item-normal device-" + device.id + ""}>
                              <RiDeviceFill className='item-icon' />
                              <span className='item-text'>{device.name}</span>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
                }
              </div>
            </div>
            <div className={(this.state.current_page.startsWith(Routes.Devices + '/') && this.state.current_page.length > 9) ? 'device-details-lay device-details-with-item' : 'device-details-lay'}>
              {(this.state.current_page === Routes.Devices || this.state.current_page === Routes.Devices + '/') &&
                <div className='device-item-empty-lay'>
                  <span className='device-item-empty-text'>Select device to view</span>
                </div>
              }
              {this.state.user_data && this.state.user_data.devices.map((device, i) => { // /devices/:device_id
                return (
                  <div className='device-main-item-lay'
                    key={'device-main-item-' + i}
                    id={'device-main-item-' + device.id}
                    style={{ display: this.state.current_page.startsWith(`${Routes.Devices}/${device.id}`) ? 'flex' : 'none' }}>
                    {this.state.user_id && (device.shown || this.state.current_page.startsWith(`${Routes.Devices}/${device.id}`)) &&
                      <DeviceItem
                        history={this.props.history}
                        current_page={this.state.current_page}
                        user_id={this.state.user_id}
                        device={device}
                        onDeviceUpdated={(device) => this.onDeviceUpdated(device)}
                      />
                    }
                  </div>
                )
              })
              }
            </div>
          </div>
          {this.state.error_loading &&
            <div className="empty-data">
              <span className="message-box"
                onClick={(e) => {
                  e.preventDefault();
                  this.onReloadClick();
                }}>
                <p>Could Not Load Data</p>
                <p>Click Here To Retry</p>
              </span>
            </div>}
          <Footer />
        </div>
      </div>
    );
  }
}
export default withRouter(Main);
