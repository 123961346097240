import './index.scss';
import './index.scss';

import * as H from 'history';

import { FaHome, FaInfo, FaSignOutAlt, FaTachometerAlt, FaUserAlt } from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { MdNotifications } from 'react-icons/md';
import React from "react";
import { RiDeviceFill } from "react-icons/ri";
import Routes from '../../../utils/routes';

export default class SideNav extends React.Component<{
    show: boolean;
    history: H.History;
    current_page: string;
    onLogOutBtnClick(): void;
}, {}> {

    render() {
        return (
            <div className={this.props.show ? 'side-nav-lay side-nav-lay-top' : 'side-nav-lay'} id="side-nav"
            // style={{ display: this.props.displayStyle }}
            >
                <Link to={Routes.Landing} className="nav-home">
                    <FaHome className='item-icon' />
                    <span className='item-text'>IoT</span>
                </Link>
                <div className="side-nav-list-lay">
                    <Link to={Routes.Dashboard} className={
                        this.props.current_page.startsWith(Routes.Dashboard) ?
                            "dash-btn dash-btn-selected" :
                            "dash-btn dash-btn-normal"}>
                        <FaTachometerAlt className='item-icon' />
                        <span className='item-text'>Dashboard</span>
                    </Link>
                    <Link to={Routes.Devices} className={
                        this.props.current_page.startsWith(Routes.Devices) ?
                            "dash-btn dash-btn-selected" :
                            "dash-btn dash-btn-normal"
                        // border-shadow
                    }>
                        <RiDeviceFill className='item-icon' />
                        <span className='item-text'>Devices</span>
                    </Link>
                    <Link to={Routes.Activities} className={
                        this.props.current_page.startsWith(Routes.Activities) ?
                            "dash-btn dash-btn-selected" :
                            "dash-btn dash-btn-normal"
                    }>
                        <MdNotifications className='item-icon' />
                        <span className='item-text'>Activities</span>
                    </Link>
                    <Link to={Routes.Account} className={
                        this.props.current_page.startsWith(Routes.Account) ?
                            "dash-btn dash-btn-selected" :
                            "dash-btn dash-btn-normal"
                    }>
                        <FaUserAlt className='item-icon' />
                        <span className='item-text'>Account</span>
                    </Link>
                    <Link to={Routes.Help} className={
                        this.props.current_page.startsWith(Routes.Help) ?
                            "dash-btn dash-btn-selected" :
                            "dash-btn dash-btn-normal"
                    }>
                        <FaInfo className='item-icon' />
                        <span className='item-text'>Help</span>
                    </Link>
                    <div className='footer-cont'>
                        <div className='footer-seg'>
                            <Link className='footer-link' to={Routes.Landing} target='_blank' rel='noreferrer'>Home</Link>
                            <Link className='footer-link' to={Routes.Privacy} target='_blank' rel='noreferrer'>Privacy</Link>
                            <Link className='footer-link' to={Routes.Terms} target='_blank' rel='noreferrer'>Terms</Link>
                        </div>
                        <div className='footer-seg'><span className='footer-text'>Copyright &copy;</span></div>
                        <div className='footer-seg'><a className='footer-link' href='https://imperialinnovations.co.tz' target='_blank' rel='noreferrer'>Imperial Innovations</a></div>
                        <div className='footer-seg'><span className='footer-text'>2022</span></div>
                        <div className='footer-seg'><span className='footer-text'>All Rights Reserved</span></div>
                    </div>
                </div>
                <div className="logout-btn-cont">
                    <div className="logout-btn" onClick={(e) => { e.preventDefault(); this.props.onLogOutBtnClick(); }}>
                        <FaSignOutAlt className='logout-icon' />
                        <span className='logout-text'>Log Out</span>
                    </div>
                </div>
            </div>
        );
    }
}