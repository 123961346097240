// import { expose } from 'comlink';
// import Highcharts from 'highcharts';
// import Period from '../period';
import { ChartStatistics, ChartSeries, ChartWorkerResult, ChartWorkerRequest } from '../models';
// import { temperatureChartWorker, lightHumidityChartWorker, powerChartWorker, doorChartWorker } from './tasks';

// declare interface SubAvgListItem {
//   x: number;
//   y1: number;
//   y2: number;
//   tt: number;
// }

export class ChartWorker {
  temperatureChartWorker(workerRequest: ChartWorkerRequest): Promise<ChartWorkerResult> {
    return new Promise<ChartWorkerResult>((resolve, _reject) => {
      const session_id: number = workerRequest.session_id;
      const device_id: string = workerRequest.device_id;
      // const originalSeries: Array<ChartSeriesX> = workerRequest.originalSeries;
      const series: Array<ChartSeries> = workerRequest.series;
      const statistics: any = workerRequest.statistics;

      // console.log('chartWorker: workerRequest: ', workerRequest);
      // console.log('temperatureChartWorker: before localSeries: 0 ', originalSeries[0].data.length);
      // console.log('temperatureChartWorker: before localSeries: 1 ', originalSeries[1].data.length);
      // console.log('temperatureChartWorker: before finalSeries: 0 ', series[0].data.length);
      // console.log('temperatureChartWorker: before finalSeries: 1 ', series[1].data.length);
      // console.log('temperatureChartWorker: before statistics:    ', statistics.data.length);

      // const avg_list: Array<SubAvgListItem> = [];

      for (var s3 = 0; s3 < statistics.data.length; s3++) {
        const stat: ChartStatistics = statistics.data[s3];
        if (stat) {
          const stat_time = stat.s ? stat.s : 0;
          series[0].data.push({ x: stat_time, y: stat.t && stat.t.i ? stat.t.i : 0 });
          series[1].data.push({ x: stat_time, y: stat.t && stat.t.o ? stat.t.o : 0 });
          // if (s3 === 0) {
          //   avg_list.push({
          //     x: stat_time,
          //     y1: stat.t && stat.t.i ? stat.t.i : 0,
          //     y2: stat.t && stat.t.o ? stat.t.o : 0,
          //     tt: 1
          //   });
          // }
          // else {
          //   const prev_: SubAvgListItem = avg_list[avg_list.length - 1];
          //   if (Period.isInSameTime(prev_.x, stat_time)) {
          //     // console.log('temp_in_out_data: same hour ' + (new Date(prev_.x).toTimeString()) + ' | ' + (new Date(stat_time).toTimeString()));
          //     prev_.x = stat_time;
          //     prev_.y1 = prev_.y1 + (stat.t && stat.t.i ? stat.t.i : 0);
          //     prev_.y2 = prev_.y2 + (stat.t && stat.t.o ? stat.t.o : 0);
          //     prev_.tt = prev_.tt + 1;
          //     avg_list[avg_list.length - 1] = prev_;
          //   }
          //   else {
          //     // console.log('temp_in_out_data: new hour ' + (new Date(stat_time).toTimeString()));
          //     avg_list.push({
          //       x: stat_time,
          //       y1: stat.t && stat.t.i ? stat.t.i : 0,
          //       y2: stat.t && stat.t.o ? stat.t.o : 0,
          //       tt: 1
          //     });
          //   }
          // }
        }
      }
      // console.log('avg_list: length: ', avg_list.length);
      // for (var c = 0; c < avg_list.length; c++) {
      //   const sub_c = avg_list[c];
      //   // console.log('avg_list:  item: ', sub_c);
      //   const y_c_0 = round(sub_c.y1 / sub_c.tt, 2);
      //   const y_c_1 = round(sub_c.y2 / sub_c.tt, 2);
      //   series[0].data.push({
      //     x: sub_c.x,
      //     y: y_c_0
      //   });
      //   series[1].data.push({
      //     x: sub_c.x,
      //     y: y_c_1
      //   });
      //   // console.log('series[0]: data: ', series[0].data);
      //   // console.log('series[1]: data: ', series[1].data);
      // }
      const _session_id: number = session_id;
      const _device_id: string = device_id;
      // const _originalSeries: Array<ChartSeries> = originalSeries;
      const _series: Array<ChartSeries> = series;

      // console.log('temperatureChartWorker: after localSeries: 0 ', _originalSeries[0].data.length);
      // console.log('temperatureChartWorker: after localSeries: 1 ', _originalSeries[1].data.length);
      // console.log('temperatureChartWorker: after finalSeries: 0 ', _series[0].data.length);
      // console.log('temperatureChartWorker: after finalSeries: 1 ', _series[1].data.length);

      resolve({
        session_id: _session_id,
        device_id: _device_id,
        // originalSeries: _originalSeries,
        series: _series,
      });
    });
  }

  lightHumidityChartWorker(workerRequest: ChartWorkerRequest): Promise<ChartWorkerResult> {
    return new Promise<ChartWorkerResult>((resolve, _reject) => {
      const session_id: number = workerRequest.session_id;
      const device_id: string = workerRequest.device_id;
      // const originalSeries: Array<ChartSeriesX> = workerRequest.originalSeries;
      const series: Array<ChartSeries> = workerRequest.series;
      const statistics: any = workerRequest.statistics;

      // console.log('chartWorker: workerRequest: ', workerRequest);
      // console.log('lightHumidityChartWorker: before localSeries: 0 ', originalSeries[0].data.length);
      // console.log('lightHumidityChartWorker: before localSeries: 1 ', originalSeries[1].data.length);
      // console.log('lightHumidityChartWorker: before finalSeries: 0 ', series[0].data.length);
      // console.log('lightHumidityChartWorker: before finalSeries: 1 ', series[1].data.length);
      // console.log('lightHumidityChartWorker: before statistics:    ', statistics.data.length);


      // const avg_list: Array<SubAvgListItem> = [];

      for (var s4 = 0; s4 < statistics.data.length; s4++) {
        const stat: ChartStatistics = statistics.data[s4];
        if (stat) {
          const stat_time = stat.s ? stat.s : 0;
          // const y1 = stat.l ? stat.l : 0;
          // const y2 = stat.h ? stat.h : 0;
          series[0].data.push({ x: stat_time, y: stat.l ? stat.l : 0 });
          series[1].data.push({ x: stat_time, y: stat.h ? stat.h : 0 });
          // if (s4 === 0) {
          //   avg_list.push({
          //     x: stat_time,
          //     y1: stat.l ? stat.l : 0,
          //     y2: stat.h ? stat.h : 0,
          //     tt: 1
          //   });
          // }
          // else {
          //   const prev_ = avg_list[avg_list.length - 1];
          //   if (Period.isInSameTime(prev_.x, stat_time)) {
          //     prev_.x = stat_time;
          //     prev_.y1 = prev_.y1 + (stat.l ? stat.l : 0);
          //     prev_.y2 = prev_.y2 + (stat.h ? stat.h : 0);
          //     prev_.tt = prev_.tt + 1;
          //     avg_list[avg_list.length - 1] = prev_;
          //   }
          //   else {
          //     avg_list.push({
          //       x: stat_time,
          //       y1: stat.l ? stat.l : 0,
          //       y2: stat.h ? stat.h : 0,
          //       tt: 1
          //     });
          //   }
          // }
        }
      }
      // for (var d = 0; d < avg_list.length; d++) {
      //   const sub_d = avg_list[d];
      //   const y_d_0 = round(sub_d.y1 / sub_d.tt, 2);
      //   const y_d_1 = round(sub_d.y2 / sub_d.tt, 2);
      //   series[0].data.push({
      //     x: sub_d.x,
      //     y: y_d_0
      //   });
      //   series[1].data.push({
      //     x: sub_d.x,
      //     y: y_d_1
      //   });
      // }

      const _session_id: number = session_id;
      const _device_id: string = device_id;
      // const _originalSeries: Array<ChartSeries> = originalSeries;
      const _series: Array<ChartSeries> = series;

      // console.log('lightHumidityChartWorker: after localSeries: 0 ', _originalSeries[0].data.length);
      // console.log('lightHumidityChartWorker: after localSeries: 1 ', _originalSeries[1].data.length);
      // console.log('lightHumidityChartWorker: after finalSeries: 0 ', _series[0].data.length);
      // console.log('lightHumidityChartWorker: after finalSeries: 1 ', _series[1].data.length);

      resolve({
        session_id: _session_id,
        device_id: _device_id,
        // originalSeries: _originalSeries,
        series: _series,
      });
    });
  }

  powerChartWorker(workerRequest: ChartWorkerRequest): Promise<ChartWorkerResult> {
    return new Promise<ChartWorkerResult>((resolve, _reject) => {
      const session_id: number = workerRequest.session_id;
      const device_id: string = workerRequest.device_id;
      // const originalSeries: Array<ChartSeriesX> = workerRequest.originalSeries;
      const series: Array<ChartSeries> = workerRequest.series;
      const statistics: any = workerRequest.statistics;

      // console.log('chartWorker: workerRequest: ', workerRequest);
      // console.log('powerChartWorker: before localSeries: 0 ', originalSeries[0].data.length);
      // console.log('powerChartWorker: before localSeries: 1 ', originalSeries[1].data.length);
      // console.log('powerChartWorker: before finalSeries: 0 ', series[0].data.length);
      // console.log('powerChartWorker: before finalSeries: 1 ', series[1].data.length);
      // console.log('powerChartWorker: before statistics:    ', statistics.data.length);

      // const avg_list: Array<SubAvgListItem> = [];

      for (var s1 = 0; s1 < statistics.data.length; s1++) {
        const stat: ChartStatistics = statistics.data[s1];
        // console.log('stat: ', stat);
        if (stat) {
          const stat_time: number = stat.s ? stat.s : 0;
          // const y1 = stat.p && stat.p.c ? stat.p.c : 0;
          // const y2 = stat.p && stat.p.o ? stat.p.o : 0;
          series[0].data.push({ x: stat_time, y: stat.p && stat.p.c ? stat.p.c : 0 });
          series[1].data.push({ x: stat_time, y: stat.p && stat.p.o ? stat.p.o : 0 });
          // if (s1 === 0) {
          //   avg_list.push({
          //     x: stat_time,
          //     y1: stat.p && stat.p.c ? stat.p.c : 0,
          //     y2: stat.p && stat.p.o ? stat.p.o : 0,
          //     tt: 1
          //   });
          // }
          // else {
          //   const prev_ = avg_list[avg_list.length - 1];
          //   if (Period.isInSameTime(prev_.x, stat_time)) {
          //     prev_.x = stat_time;
          //     prev_.y1 = /*prev_.y1 + */(stat.p && stat.p.c ? stat.p.c : 0);
          //     prev_.y2 = prev_.y2 + (stat.p && stat.p.o ? stat.p.o : 0);
          //     prev_.tt = prev_.tt + 1;
          //     avg_list[avg_list.length - 1] = prev_;
          //   }
          //   else {
          //     avg_list.push({
          //       x: stat_time,
          //       y1: stat.p && stat.p.c ? stat.p.c : 0,
          //       y2: stat.p && stat.p.o ? stat.p.o : 0,
          //       tt: 1
          //     });
          //   }
          // }
        }
      }
      // for (var a = 0; a < avg_list.length; a++) {
      //   const sub_a = avg_list[a];
      //   const y_a_0 = sub_a.y1; //round(sub_.y1 / sub_.tt, 2)
      //   const y_a_1 = round(sub_a.y2 / sub_a.tt, 2);
      //   series[0].data.push({
      //     x: sub_a.x,
      //     y: y_a_0
      //   });
      //   series[1].data.push({
      //     x: sub_a.x,
      //     y: y_a_1
      //   });
      // }
      const _session_id: number = session_id;
      const _device_id: string = device_id;
      // const _originalSeries: Array<ChartSeries> = originalSeries;
      const _series: Array<ChartSeries> = series;

      // console.log('powerChartWorker: after localSeries: 0 ', _originalSeries[0].data.length);
      // console.log('powerChartWorker: after localSeries: 1 ', _originalSeries[1].data.length);
      // console.log('powerChartWorker: after finalSeries: 0 ', _series[0].data.length);
      // console.log('powerChartWorker: after finalSeries: 1 ', _series[1].data.length);

      resolve({
        session_id: _session_id,
        device_id: _device_id,
        // originalSeries: _originalSeries,
        series: _series,
      });
    });
  }

  doorChartWorker(workerRequest: ChartWorkerRequest): Promise<ChartWorkerResult> {
    return new Promise<ChartWorkerResult>((resolve, _reject) => {

      const session_id: number = workerRequest.session_id;
      const device_id: string = workerRequest.device_id;
      // const originalSeries: Array<ChartSeriesX> = workerRequest.originalSeries;
      const series: Array<ChartSeries> = workerRequest.series;
      const statistics: any = workerRequest.statistics;

      // console.log('chartWorker: workerRequest: ', workerRequest);
      // console.log('doorChartWorker: before localSeries: 0 ', originalSeries[0].data.length);
      // console.log('doorChartWorker: before localSeries: 1 ', originalSeries[1].data.length);
      // console.log('doorChartWorker: before finalSeries: 0 ', series[0].data.length);
      // console.log('doorChartWorker: before finalSeries: 1 ', series[1].data.length);
      // console.log('doorChartWorker: before statistics:    ', statistics.data.length);

      // const avg_list: Array<SubAvgListItem> = [];

      for (var s2 = 0; s2 < statistics.data.length; s2++) {
        const stat: ChartStatistics = statistics.data[s2];
        if (stat) {
          const stat_time: number = stat.s ? stat.s : 0;
          // const y1 = stat.d && stat.d.c ? stat.d.c : 0;
          // const y2 = stat.d && stat.d.o ? stat.d.o : 0;
          series[0].data.push({ x: stat_time, y: stat.d && stat.d.c ? stat.d.c : 0 });
          series[1].data.push({ x: stat_time, y: stat.d && stat.d.o ? stat.d.o : 0 });
          // if (s2 === 0) {
          //   avg_list.push({
          //     x: stat_time,
          //     y1: stat.d && stat.d.c ? stat.d.c : 0,
          //     y2: stat.d && stat.d.o ? stat.d.o : 0,
          //     tt: 1
          //   });
          // }
          // else {
          //   const prev_ = avg_list[avg_list.length - 1];
          //   if (Period.isInSameTime(prev_.x, stat_time)) {
          //     prev_.x = stat_time;
          //     prev_.y1 = /*prev_.y1 + */(stat.d && stat.d.c ? stat.d.c : 0);
          //     prev_.y2 = prev_.y2 + (stat.d && stat.d.o ? stat.d.o : 0);
          //     prev_.tt = prev_.tt + 1;
          //     avg_list[avg_list.length - 1] = prev_;
          //   }
          //   else {
          //     avg_list.push({
          //       x: stat_time,
          //       y1: stat.d && stat.d.c ? stat.d.c : 0,
          //       y2: stat.d && stat.d.o ? stat.d.o : 0,
          //       tt: 1
          //     });
          //   }
          // }
        }
      }
      // for (var b = 0; b < avg_list.length; b++) {
      //   const sub_b = avg_list[b];
      //   const y_b_0 = sub_b.y1; //round(sub_.y1 / sub_.tt, 2)
      //   const y_b_1 = round(sub_b.y2 / sub_b.tt, 2);
      //   series[0].data.push({
      //     x: sub_b.x,
      //     y: y_b_0
      //   });
      //   series[1].data.push({
      //     x: sub_b.x,
      //     y: y_b_1
      //   });
      // }
      const _session_id: number = session_id;
      const _device_id: string = device_id;
      // // const _originalSeries: Array<ChartSeries> = originalSeries;
      const _series: Array<ChartSeries> = series;

      // // console.log('doorChartWorker: after localSeries: 0 ', _originalSeries[0].data.length);
      // // console.log('doorChartWorker: after localSeries: 1 ', _originalSeries[1].data.length);
      // console.log('doorChartWorker: after finalSeries: 0 ', _series[0].data.length);
      // console.log('doorChartWorker: after finalSeries: 1 ', _series[1].data.length);

      resolve({
        session_id: _session_id,
        device_id: _device_id,
        // originalSeries: _originalSeries,
        series: _series,
      });

    });
  }
}

// class LightWorker {
//   lightHumidityChartWorker(workerRequest: ChartWorkerRequest): Promise<ChartWorkerResult> {
//     return new Promise<ChartWorkerResult>((resolve, _reject) => {
//       const session_id: number = workerRequest.session_id;
//       const device_id: string = workerRequest.device_id;
//       const originalSeries: Array<ChartSeriesX> = workerRequest.originalSeries;
//       const series: Array<ChartSeries> = workerRequest.series;
//       const statistics: any = workerRequest.statistics;

//       // console.log('chartWorker: workerRequest: ', workerRequest);
//       console.log('lightHumidityChartWorker: before localSeries: 0 ', originalSeries[0].data.length);
//       console.log('lightHumidityChartWorker: before localSeries: 1 ', originalSeries[1].data.length);
//       console.log('lightHumidityChartWorker: before finalSeries: 0 ', series[0].data.length);
//       console.log('lightHumidityChartWorker: before finalSeries: 1 ', series[1].data.length);
//       console.log('lightHumidityChartWorker: before statistics:    ', statistics.data.length);


//       const avg_list: Array<SubAvgListItem> = [];

//       for (var s4 = 0; s4 < statistics.data.length; s4++) {
//         const stat: ChartStatistics = statistics.data[s4];
//         if (stat) {
//           const stat_time = stat.s ? stat.s : 0;
//           // const y1 = stat.l ? stat.l : 0;
//           // const y2 = stat.h ? stat.h : 0;
//           originalSeries[0].data.push({ x: stat_time, y: stat.l ? stat.l : 0 });
//           originalSeries[1].data.push({ x: stat_time, y: stat.h ? stat.h : 0 });
//           if (s4 === 0) {
//             avg_list.push({
//               x: stat_time,
//               y1: stat.l ? stat.l : 0,
//               y2: stat.h ? stat.h : 0,
//               tt: 1
//             });
//           }
//           else {
//             const prev_ = avg_list[avg_list.length - 1];
//             if (Period.isInSameTime(prev_.x, stat_time)) {
//               prev_.x = stat_time;
//               prev_.y1 = prev_.y1 + (stat.l ? stat.l : 0);
//               prev_.y2 = prev_.y2 + (stat.h ? stat.h : 0);
//               prev_.tt = prev_.tt + 1;
//               avg_list[avg_list.length - 1] = prev_;
//             }
//             else {
//               avg_list.push({
//                 x: stat_time,
//                 y1: stat.l ? stat.l : 0,
//                 y2: stat.h ? stat.h : 0,
//                 tt: 1
//               });
//             }
//           }
//         }
//       }
//       for (var d = 0; d < avg_list.length; d++) {
//         const sub_d = avg_list[d];
//         const y_d_0 = round(sub_d.y1 / sub_d.tt, 2);
//         const y_d_1 = round(sub_d.y2 / sub_d.tt, 2);
//         series[0].data.push({
//           x: sub_d.x,
//           y: y_d_0
//         });
//         series[1].data.push({
//           x: sub_d.x,
//           y: y_d_1
//         });
//       }

//       const _session_id: number = session_id;
//       const _device_id: string = device_id;
//       const _originalSeries: Array<ChartSeries> = originalSeries;
//       const _series: Array<ChartSeries> = series;

//       console.log('lightHumidityChartWorker: after localSeries: 0 ', _originalSeries[0].data.length);
//       console.log('lightHumidityChartWorker: after localSeries: 1 ', _originalSeries[1].data.length);
//       console.log('lightHumidityChartWorker: after finalSeries: 0 ', _series[0].data.length);
//       console.log('lightHumidityChartWorker: after finalSeries: 1 ', _series[1].data.length);

//       resolve({
//         session_id: _session_id,
//         device_id: _device_id,
//         originalSeries: _originalSeries,
//         series: _series,
//       });
//     });
//   }
// }

// class PowerWorker {
//   powerChartWorker(workerRequest: ChartWorkerRequest): Promise<ChartWorkerResult> {
//     return new Promise<ChartWorkerResult>((resolve, _reject) => {
//       const session_id: number = workerRequest.session_id;
//       const device_id: string = workerRequest.device_id;
//       const originalSeries: Array<ChartSeriesX> = workerRequest.originalSeries;
//       const series: Array<ChartSeries> = workerRequest.series;
//       const statistics: any = workerRequest.statistics;

//       // console.log('chartWorker: workerRequest: ', workerRequest);
//       console.log('powerChartWorker: before localSeries: 0 ', originalSeries[0].data.length);
//       console.log('powerChartWorker: before localSeries: 1 ', originalSeries[1].data.length);
//       console.log('powerChartWorker: before finalSeries: 0 ', series[0].data.length);
//       console.log('powerChartWorker: before finalSeries: 1 ', series[1].data.length);
//       console.log('powerChartWorker: before statistics:    ', statistics.data.length);

//       const avg_list: Array<SubAvgListItem> = [];

//       for (var s1 = 0; s1 < statistics.data.length; s1++) {
//         const stat: ChartStatistics = statistics.data[s1];
//         // console.log('stat: ', stat);
//         if (stat) {
//           const stat_time: number = stat.s ? stat.s : 0;
//           // const y1 = stat.p && stat.p.c ? stat.p.c : 0;
//           // const y2 = stat.p && stat.p.o ? stat.p.o : 0;
//           originalSeries[0].data.push({ x: stat_time, y: stat.p && stat.p.c ? stat.p.c : 0 });
//           originalSeries[1].data.push({ x: stat_time, y: stat.p && stat.p.o ? stat.p.o : 0 });
//           if (s1 === 0) {
//             avg_list.push({
//               x: stat_time,
//               y1: stat.p && stat.p.c ? stat.p.c : 0,
//               y2: stat.p && stat.p.o ? stat.p.o : 0,
//               tt: 1
//             });
//           }
//           else {
//             const prev_ = avg_list[avg_list.length - 1];
//             if (Period.isInSameTime(prev_.x, stat_time)) {
//               prev_.x = stat_time;
//               prev_.y1 = /*prev_.y1 + */(stat.p && stat.p.c ? stat.p.c : 0);
//               prev_.y2 = prev_.y2 + (stat.p && stat.p.o ? stat.p.o : 0);
//               prev_.tt = prev_.tt + 1;
//               avg_list[avg_list.length - 1] = prev_;
//             }
//             else {
//               avg_list.push({
//                 x: stat_time,
//                 y1: stat.p && stat.p.c ? stat.p.c : 0,
//                 y2: stat.p && stat.p.o ? stat.p.o : 0,
//                 tt: 1
//               });
//             }
//           }
//         }
//       }
//       for (var a = 0; a < avg_list.length; a++) {
//         const sub_a = avg_list[a];
//         const y_a_0 = sub_a.y1; //round(sub_.y1 / sub_.tt, 2)
//         const y_a_1 = round(sub_a.y2 / sub_a.tt, 2);
//         series[0].data.push({
//           x: sub_a.x,
//           y: y_a_0
//         });
//         series[1].data.push({
//           x: sub_a.x,
//           y: y_a_1
//         });
//       }
//       const _session_id: number = session_id;
//       const _device_id: string = device_id;
//       const _originalSeries: Array<ChartSeries> = originalSeries;
//       const _series: Array<ChartSeries> = series;

//       console.log('powerChartWorker: after localSeries: 0 ', _originalSeries[0].data.length);
//       console.log('powerChartWorker: after localSeries: 1 ', _originalSeries[1].data.length);
//       console.log('powerChartWorker: after finalSeries: 0 ', _series[0].data.length);
//       console.log('powerChartWorker: after finalSeries: 1 ', _series[1].data.length);

//       resolve({
//         session_id: _session_id,
//         device_id: _device_id,
//         originalSeries: _originalSeries,
//         series: _series,
//       });
//     });
//   }
// }

// class DoorWorker {
//   doorChartWorker(workerRequest: ChartWorkerRequest): Promise<ChartWorkerResult> {
//     return new Promise<ChartWorkerResult>((resolve, _reject) => {

//       const session_id: number = workerRequest.session_id;
//       const device_id: string = workerRequest.device_id;
//       const originalSeries: Array<ChartSeriesX> = workerRequest.originalSeries;
//       const series: Array<ChartSeries> = workerRequest.series;
//       const statistics: any = workerRequest.statistics;

//       // console.log('chartWorker: workerRequest: ', workerRequest);
//       console.log('doorChartWorker: before localSeries: 0 ', originalSeries[0].data.length);
//       console.log('doorChartWorker: before localSeries: 1 ', originalSeries[1].data.length);
//       console.log('doorChartWorker: before finalSeries: 0 ', series[0].data.length);
//       console.log('doorChartWorker: before finalSeries: 1 ', series[1].data.length);
//       console.log('doorChartWorker: before statistics:    ', statistics.data.length);

//       const avg_list: Array<SubAvgListItem> = [];

//       for (var s2 = 0; s2 < statistics.data.length; s2++) {
//         const stat: ChartStatistics = statistics.data[s2];
//         if (stat) {
//           const stat_time: number = stat.s ? stat.s : 0;
//           // const y1 = stat.d && stat.d.c ? stat.d.c : 0;
//           // const y2 = stat.d && stat.d.o ? stat.d.o : 0;
//           originalSeries[0].data.push({ x: stat_time, y: stat.d && stat.d.c ? stat.d.c : 0 });
//           originalSeries[1].data.push({ x: stat_time, y: stat.d && stat.d.o ? stat.d.o : 0 });
//           if (s2 === 0) {
//             avg_list.push({
//               x: stat_time,
//               y1: stat.d && stat.d.c ? stat.d.c : 0,
//               y2: stat.d && stat.d.o ? stat.d.o : 0,
//               tt: 1
//             });
//           }
//           else {
//             const prev_ = avg_list[avg_list.length - 1];
//             if (Period.isInSameTime(prev_.x, stat_time)) {
//               prev_.x = stat_time;
//               prev_.y1 = /*prev_.y1 + */(stat.d && stat.d.c ? stat.d.c : 0);
//               prev_.y2 = prev_.y2 + (stat.d && stat.d.o ? stat.d.o : 0);
//               prev_.tt = prev_.tt + 1;
//               avg_list[avg_list.length - 1] = prev_;
//             }
//             else {
//               avg_list.push({
//                 x: stat_time,
//                 y1: stat.d && stat.d.c ? stat.d.c : 0,
//                 y2: stat.d && stat.d.o ? stat.d.o : 0,
//                 tt: 1
//               });
//             }
//           }
//         }
//       }
//       for (var b = 0; b < avg_list.length; b++) {
//         const sub_b = avg_list[b];
//         const y_b_0 = sub_b.y1; //round(sub_.y1 / sub_.tt, 2)
//         const y_b_1 = round(sub_b.y2 / sub_b.tt, 2);
//         series[0].data.push({
//           x: sub_b.x,
//           y: y_b_0
//         });
//         series[1].data.push({
//           x: sub_b.x,
//           y: y_b_1
//         });
//       }
//       const _session_id: number = session_id;
//       const _device_id: string = device_id;
//       const _originalSeries: Array<ChartSeries> = originalSeries;
//       const _series: Array<ChartSeries> = series;

//       console.log('doorChartWorker: after localSeries: 0 ', _originalSeries[0].data.length);
//       console.log('doorChartWorker: after localSeries: 1 ', _originalSeries[1].data.length);
//       console.log('doorChartWorker: after finalSeries: 0 ', _series[0].data.length);
//       console.log('doorChartWorker: after finalSeries: 1 ', _series[1].data.length);

//       resolve({
//         session_id: _session_id,
//         device_id: _device_id,
//         originalSeries: _originalSeries,
//         series: _series,
//       });

//     });
//   }
// }

// const round = (value: number, exp: number): number => {
//   if (typeof exp === 'undefined' || + exp === 0)
//     return Math.round(value);

//   value = + value;
//   exp = + exp;

//   if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
//     return NaN;

//   // Shift
//   var _value = value.toString().split('e');
//   value = Math.round(+ (_value[0] + 'e' + (_value[1] ? (+ _value[1] + exp) : exp)));

//   // Shift back
//   var __value = value.toString().split('e');
//   return + (__value[0] + 'e' + (__value[1] ? (+ __value[1] - exp) : -exp));
// }

(global as any).$RefreshReg$ = () => { };
(global as any).$RefreshSig$$ = () => () => { };

export type ChartWorkerBase = typeof ChartWorker;

// expose(ChartWorker);