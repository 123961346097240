import './index.scss';

import { DeviceCircle, DevicePolygonPoint } from '../models';

import Geocode from "react-geocode";
import GoogleMapReact from 'google-map-react';
import React from 'react';

export const API_KEY: string = "AIzaSyA-wq7bDNsJ3NJxsyCZdyvS0gkR8pFsXDU";

/**
 * usefull links:
 * https://developers.google.com/maps/documentation/javascript/examples/map-simple
 * http://rosettacode.org/wiki/Ray-casting_algorithm#JavaScript
 * http://rosettacode.org/wiki/Ray-casting_algorithm#PHP
 * https://assemblysys.com/php-point-in-polygon-algorithm/
 */

type CoolerMapProps = {
  is_dashboard: boolean;
  is_setting: boolean;
  locations: Array<{
    default_center: {
      lat: number,
      lng: number,
    } | null,
    current: {
      lat: number,
      lng: number,
    }
    last_updated: number,
    circle: DeviceCircle;
    polygon: Array<DevicePolygonPoint>;
  }>;
  location: GoogleMapReact.Coords | undefined;
  polygon?: DevicePolygonPoint[];
  circle?: DeviceCircle;
  is_location_data_loaded: boolean;
  last_location_updated: number;
  default_center?: { lat: number, lng: number } | null;
  //
  geofence_type?: 'circle' | 'polygon';
  onCenterChnged?: (center: { lat: number, lng: number }) => void;
  onCircleChnged?: (circle: DeviceCircle) => void;
  onPolygonChnged?: (polygon: DevicePolygonPoint[]) => void;
}

type CoolerMapState = {
  location: GoogleMapReact.Coords | undefined,
  country: string,
  city: string,
  address: string,
  polygon: DevicePolygonPoint[],
  is_center_selection: boolean,
  default_center: {
    lat: number,
    lng: number,
  } | null,
}

export default class CoolerMap extends React.PureComponent<CoolerMapProps, CoolerMapState> {

  private location: GoogleMapReact.Coords = { lat: 0, lng: 0 };// lat: -6.786525, // lng: 39.274040
  private map: any = null;
  private maps: any = null;
  private polygon: any = null;
  private circle: any = null;
  private input_check_ref: React.RefObject<HTMLInputElement> = React.createRef();

  private didMount: boolean = false;

  constructor(props: CoolerMapProps) {
    super(props);

    Geocode.setApiKey(API_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("tz");

    this.state = {
      location: this.props.location ? this.props.location : { lat: 0, lng: 0 },
      country: "",
      city: "",
      address: "",
      polygon: [],
      is_center_selection: true,
      default_center: null,
    }

    this.location = this.props.location ? this.props.location : { lat: 0, lng: 0 };
  }

  componentDidMount() {
    this.didMount = true;
    this.getAddress();
    this.setState({
      default_center: this.props.default_center ? this.props.default_center : null,
    });
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  componentDidUpdate(prevProps: CoolerMapProps, _prevState: CoolerMapState) {
    if (!this.didMount) {
      return;
    }
    // console.log('componentDidUpdate: props', this.props);
    if (prevProps.location !== this.props.location) {
      // console.log('componentDidUpdate: location changed');
      this.location = this.props.location ? this.props.location : { lat: 0, lng: 0 };
      this.getAddress();
    }
    if (prevProps.geofence_type !== this.props.geofence_type) {
      // console.log('componentDidUpdate: geofence_type changed');
      if (this.props.geofence_type) {
        if (this.props.geofence_type === 'circle') {
          this.reDrawCircle();
        }
        else if (this.props.geofence_type === 'polygon') {
          this.reDrawPolygon();
        }
      }
    }
    if (prevProps.circle !== this.props.circle) {
      // console.log('componentDidUpdate: circle changed');
      if (this.props.geofence_type === 'circle') {
        this.reDrawCircle();
      }
    }
    if (prevProps.default_center !== this.props.default_center) {
      // console.log('componentDidUpdate: default_center changed');
      if (this.props.default_center) {
        this.setState({ default_center: this.props.default_center });
      }
      if (this.props.geofence_type === 'circle') {
        setTimeout(() => this.reDrawCircle(), 100);
      }
    }
  }

  private getAddress() {
    if (!this.props.is_dashboard && this.location.lat !== 0 && this.location.lng !== 0) {
      this.setState({ location: this.props.location })
      Geocode.fromLatLng((this.location.lat).toString(), (this.location.lng).toString()).then(
        (response) => {
          // console.log('geocode: result: ', response);
          const address = response.results[0].formatted_address;
          let city, state, country;
          for (let i = 0; i < response.results[0].address_components.length; i++) {
            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          // console.log("city: ", city);
          // console.log("state: ", state);
          // console.log("country: ", country);
          // console.log("address: ", address);

          if (country) {
            this.setState({ country: country });
          }
          if (city) {
            this.setState({ city: city });
          }
          else if (state) {
            this.setState({ city: state })
          }
          if (address) {
            this.setState({ address: address });
          }
          this.forceUpdate();
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  private onMapClick(value: GoogleMapReact.ClickEventValue) {
    if (!this.didMount || !this.props.is_setting || !this.maps || isNaN(value.lat) || isNaN(value.lng)) {
      return;
    }
    if (this.state.is_center_selection) {
      this.setState({
        default_center: {
          lat: value.lat,
          lng: value.lng,
        }
      });
      this.forceUpdate();
      setTimeout(() => {
        if (this.props.geofence_type && this.props.geofence_type === 'circle') {
          if (this.props.circle) {
            this.drawCircle(this.props.circle.radius, false);
          }
        }
      }, 100);
      if (this.props.onCenterChnged && this.props.is_setting) {
        this.props.onCenterChnged({ lat: value.lat, lng: value.lng });
      }
      return
    }
    if (this.props.geofence_type) {
      if (this.props.geofence_type === 'polygon') {
        if (this.circle) {
          this.circle.setMap(null);
          this.circle = null;
        }
        const polygon = this.state.polygon;
        polygon.push({
          lng: value.lng,
          lat: value.lat
        });
        this.setState({ polygon: polygon });
        this.forceUpdate();
        this.drawPolygon(polygon, false);
      }
      else if (this.props.geofence_type === 'circle' &&
        this.state.default_center &&
        !isNaN(this.state.default_center.lat) &&
        !isNaN(this.state.default_center.lng)) {
        const p1 = new this.maps.LatLng(value.lat, value.lng);
        const p2 = new this.maps.LatLng(this.state.default_center.lat, this.state.default_center.lng);
        const distance_from_center = this.maps.geometry.spherical.computeDistanceBetween(p1, p2) + 0;
        // convert to integer if contains decimal
        const radius = Math.round(distance_from_center);
        this.drawCircle(radius, false);
      }
    }
  }

  private drawPolygon(polygon: DevicePolygonPoint[], from_input: boolean) {
    if (!this.didMount || !this.map || !this.maps || polygon.length === 0) {
      return;
    }
    if (this.polygon) {
      this.polygon.setMap(null);
      this.polygon = null;
    }
    this.polygon = new this.maps.Polygon({
      path: polygon, //pointers.map(p => ({ lat: p.lat, lng: p.lng })),
      geodesic: true,
      strokeColor: '#004ac1',
      strokeOpacity: 0.8,
      strokeWeight: 1.0,
      fillColor: '#004ac1e2',
      fillOpacity: 0.35
    });
    this.polygon.setMap(this.map);
    // console.log('pointers: ', this.state.pointers);
    // console.log('latLngs: ', latLngs);
    if (!from_input && this.props.onPolygonChnged && this.props.is_setting) {
      this.props.onPolygonChnged(polygon);
    }
  }

  private drawCircle(radius: number, from_input: boolean) {
    if (!this.didMount || !this.map || !this.maps || radius === 0) { return; }
    if (this.state.default_center) {
      this.removePolygon();
      this.removeCircle();

      this.circle = new this.maps.Circle({
        strokeColor: '#004ac1',
        strokeOpacity: 0.8,
        strokeWeight: 1.0,
        fillColor: '#004ac1e2',
        fillOpacity: 0.35,
        map: this.map,
        center: { lat: this.state.default_center.lat, lng: this.state.default_center.lng },
        radius: radius,
      });
    }
    if (!from_input && this.props.onCircleChnged && this.props.is_setting) {
      this.props.onCircleChnged({ radius: radius });
    }
  }

  private reDrawPolygon() {
    this.removeCircle();
    if (this.props.polygon && this.props.polygon.length > 0) {
      // console.log('drawing polygon called');
      this.setState({ polygon: this.props.polygon })
      this.drawPolygon(this.props.polygon, true);
    }
  }

  private reDrawCircle() {
    this.removePolygon();
    if (this.props.circle &&
      !isNaN(this.props.circle.radius) &&
      this.props.circle.radius > 0 &&
      this.state.default_center) {
      // console.log('drawing circle called');
      this.drawCircle(this.props.circle.radius, true);
    }
  }

  private onRemoveAllClick() {
    if (!this.didMount) {
      return;
    }
    if (!this.props.is_setting) {
      return;
    }
    if (this.polygon) {
      this.polygon.setMap(null);
      this.polygon = null;
    }
    this.setState({ polygon: [] });
    this.forceUpdate();
    if (this.props.onPolygonChnged && this.props.is_setting) {
      this.props.onPolygonChnged([]);
    }
  }

  private onRemoveLastClick() {
    if (!this.didMount) {
      return;
    }
    const polygon = this.state.polygon;
    polygon.pop();
    this.setState({ polygon: polygon });
    if (this.polygon) {
      this.polygon.setMap(null);
      this.polygon = null;
    }
    this.setState({ polygon: polygon });
    this.forceUpdate();
    this.drawPolygon(polygon, false);
    if (this.props.onPolygonChnged && this.props.is_setting) {
      this.props.onPolygonChnged(polygon);
    }
  }

  private removePolygon() {
    if (this.polygon) {
      this.polygon.setMap(null);
      this.polygon = null;
    }
    this.setState({ polygon: [] });
  }

  private removeCircle() {
    if (this.circle) {
      this.circle.setMap(null);
      this.circle = null;
    }
  }

  private onCheckClick(e: any) {
    e.preventDefault();
    if (!this.didMount || !this.input_check_ref.current) {
      return;
    }

    const is_checked = this.input_check_ref.current.checked;
    this.input_check_ref.current.checked = !is_checked;
    this.setState({ is_center_selection: !is_checked });
  }

  render() {
    const defaultProps = {
      center: {
        lat: -6.786525,
        lng: 39.274040
        // lat: -6.200056,
        // lng: 35.7297673
      },
      zoom: this.props.is_dashboard ? 5 : 11
    };

    return (
      // Important! Always set the container height explicitly
      <div className="map-cont">
        {!this.props.is_setting &&
          <>
            {this.props.is_dashboard && <p className="loc-title-dashboard item-title">Locations</p>}
            {!this.props.is_dashboard &&
              <>
                <p className="loc-title item-title">Location</p>
                {/* <i className="fa fa-map-marker item-icon" aria-hidden="true"></i> */}
                {!this.props.is_location_data_loaded &&
                  <div className="loading-map">
                    <p className="loc-info">Loading...</p>
                  </div>
                }
                {(!this.props.location || this.props.location.lat === 0 || this.props.location.lng === 0) &&
                  <>
                    {!this.props.default_center && <i className="fa fa-map-marker item-icon" aria-hidden="true"></i>}
                    {this.props.is_location_data_loaded && !this.props.default_center &&
                      <>
                        <div className="loading-map" />
                        <p className="loc-info">No Location Data Available.</p>
                      </>
                    }
                  </>
                }
                {/* {!this.props.is_dashboard && !this.props.is_location_data_loaded && <p className="loc-info">Loading...</p>} */}
              </>
            }
          </>
        }
        {this.props.is_setting &&
          <>
            {this.state.polygon.length === 0 &&
              <p className="loc-title-setting">{this.state.is_center_selection ? 'Click to add/change center' : (this.props.geofence_type && this.props.geofence_type === 'polygon' ? 'Click to add geofence' : 'Click to change geofence radius')}</p>
            }
            {this.state.polygon.length > 0 && this.props.geofence_type && this.props.geofence_type === 'polygon' &&
              <div className='loc-setting-btns'>
                {/* {this.state.pointers.length > 2 &&
                  <div className='loc-draw-btn'
                    onClick={(e) => this.onDrawClick()}
                  >Draw</div>
                } */}
                <div className='loc-draw-btn'
                  onClick={() => this.onRemoveLastClick()}
                >Undo</div>
                <div className='loc-remove-btn'
                  onClick={() => this.onRemoveAllClick()}
                >Remove All</div>
              </div>
            }
            <div className={'center-input-lay'} onClick={(e) => this.onCheckClick(e)} >
              <input
                tabIndex={-1}
                ref={this.input_check_ref}
                className='center-input-field'
                name='center-input-field'
                type='checkbox'
                style={{ pointerEvents: 'none' }}
                readOnly={true}
                checked={this.state.is_center_selection}
                onClick={(e) => this.onCheckClick(e)}
              />
              <span className='center-input-text'>Select Default center</span>
            </div>
          </>
        }
        <GoogleMapReact
          bootstrapURLKeys={{ key: API_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          options={(maps: GoogleMapReact.Maps) => {
            // next props are exposed at maps
            // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
            // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
            // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
            // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
            // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
            return {
              zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER,
                style: maps.ZoomControlStyle.SMALL
              },
              mapTypeControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT
              },
              mapTypeControl: true
            }
          }}
          onClick={(e) => this.onMapClick(e)}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            if (!this.didMount) {
              return;
            }
            this.map = map;
            this.maps = maps;
            if (this.props.polygon && this.props.polygon.length > 0 &&
              this.props.geofence_type && this.props.geofence_type === 'polygon') {
              this.setState({ polygon: this.props.polygon });
              this.removeCircle();
              this.drawPolygon(this.props.polygon, true);
            } else if (this.props.circle && this.props.circle.radius > 0 &&
              this.props.geofence_type && this.props.geofence_type === 'circle') {
              this.removePolygon();
              this.drawCircle(this.props.circle.radius, true);
            }
          }}
        >
          {this.state.default_center &&
            !isNaN(this.state.default_center.lat) &&
            !isNaN(this.state.default_center.lng) &&
            this.state.default_center.lat !== 0 &&
            this.state.default_center.lng !== 0 &&
            <PointMarker
              is_default={true}
              lat={this.state.default_center.lat}
              lng={this.state.default_center.lng}
            />
          }
          {!this.props.is_setting &&
            this.props.is_location_data_loaded &&
            this.state.location &&
            !isNaN(this.state.location.lat) &&
            !isNaN(this.state.location.lng) &&
            this.state.location.lat !== 0 &&
            this.state.location.lng !== 0 &&
            <Marker
              lat={this.state.location.lat}
              lng={this.state.location.lng}
              //
              country={this.state.country}
              city={this.state.city}
              address={this.state.address}
              last_location_updated={this.props.last_location_updated} />
          }
          {this.props.is_setting && this.state.polygon.map((pointer, index) => {
            return (
              <PointMarker
                key={index}
                is_default={false}
                lat={pointer.lat}
                lng={pointer.lng}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

export class Marker extends React.Component<{
  last_location_updated: number;
  lat: number;
  lng: number;
  country: string;
  city: string;
  address: string;

  // lat={-6.786525}
  // lng={39.274040}
}, {}> {
  render() {

    // console.log("Location: " + this.props.lat + ", " + this.props.lng);

    function getDisplayTime(input_int: number) {
      return input_int < 10 ? ("0" + input_int) : input_int;
    }

    return (
      <div className="marker-container">
        <div className="marker-modal" id="marker-modal" data-html2canvas-ignore="true">
          <div className="marker-modal-name">{this.props.country}</div>
          <p color="#666" className="marker-modal-address">{this.props.city}</p>
          <p
            // href="https://www.google.com/maps/search/?api=1&amp;query=Tunisia%20Rd,%20Dar%20es%20Salaam,%20Tanzania" 
            // target="_blank" 
            color="#666"
            // rel="noreferrer"
            className="marker-modal-address">{this.props.address}</p>
          <div className="marker-modal-notes" color="#393939">
            {/* <p>{this.props.address}</p> */}
            <p>Last Update:&nbsp;&nbsp;
              {
                (
                  (new Date(this.props.last_location_updated)).getDate() === (new Date()).getDate() &&
                    (new Date(this.props.last_location_updated)).getMonth() === (new Date()).getMonth() &&
                    (new Date(this.props.last_location_updated)).getFullYear() === (new Date()).getFullYear() ?
                    "Today" :
                    (new Date(this.props.last_location_updated)).toDateString()
                )
                + " at " + getDisplayTime((new Date(this.props.last_location_updated)).getHours()) + ":" + getDisplayTime((new Date(this.props.last_location_updated)).getMinutes()) + ":" + getDisplayTime((new Date(this.props.last_location_updated)).getSeconds())
              }</p>
          </div>
          <div className="marker-modal-tail"></div>
        </div>
        <div className="marker-pointer">
          <div className="marker-shape" title='current location'></div>
        </div>
      </div>
    )
  }
}

export class PointMarker extends React.Component<{
  is_default: boolean
  lat: number,
  lng: number,
}, {}> {
  render() {
    return (
      <div className="marker-pointer-container">
        <div className="marker-pointer">
          <div className={this.props.is_default ? "marker-shape-current" : "marker-shape"} title={this.props.is_default ? 'Default Location' : 'Location Point'} />
        </div>
      </div>
    )
  }
}