import './index.scss';

import * as H from 'history';

import { ChartWorkerRequest, ChartWorkerResult, Device, DeviceCircle, DevicePolygonPoint } from '../../models';

import { BiArrowBack } from 'react-icons/bi';
import { ChartWorker } from '../../tasks/worker';
import CoolerMap from '../../map';
import DatabaseManager from "../../../service";
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import { DateRangePicker } from 'rsuite';
import { FaTimes } from 'react-icons/fa';
import GoogleMapReact from 'google-map-react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Link } from 'react-router-dom';
import Period from '../../../utils/period';
import React from "react";
import Routes from '../../../utils/routes';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);
require('highcharts/modules/boost')(Highcharts);

// import IotChart, { ChartModal } from '../charts';
// import * as Comlink from 'comlink';
// import * as workerPath from "../file-loader?name=[name].js!./tasks.worker";


const { combine, before, afterToday } = DateRangePicker;

class ChartOps {

    private options: Highcharts.Options = {
        global: {
            // useUTC: false
        },
        chart: {
            // id: this.id,
            // height: 400,
            // width: '100%',
            type: 'area',
            // stacked: true,
            // foreColor: "#ccc",
            // backgroundColor: 'inherit',
            zoomType: 'x',
            events: {

            }
        },
        colors: ['#3fb0e4', '#811796'],
        title: {
            text: '',
            align: 'left',
            style: {
                fontSize: '12px',
                fontFamily: 'inherit',
                fontWeight: 'bold',
                color: 'var(--color-edit-txt)'
            }
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen', 'separator', 'downloadPNG',
                        'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
                    ]
                },
            },
            enabled: true,
        },
        navigation: {
            buttonOptions: {
                //align: 'right',
                //verticalAlign: 'top',
                y: 0
            }
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: false,
                    symbol: 'circle'
                },
                lineWidth: 2
            }
        },
        credits: { enabled: false },
        // tooltip: {
        //   // formatter: () => {
        //   //   return 'x: ' + Highcharts.dateFormat('%e %b %y %H:%M:%S', this.x) +
        //   //     ' y: ' + this.y.toFixed(2);
        //   // }
        // },
        time: {
            useUTC: false,
        },
        xAxis: {
            type: 'datetime',
            allowDecimals: true,
            startOnTick: true,
            // minRange: 1000 * 60 * 60,
            // dateTimeLabelFormats:
            // gridLineWidth: 1,
            // gridLineColor: 'red',
            // lineColor: 'var(--color-edit-txt)',
            // tickColor: 'var(--color-edit-txt)',
            labels: {
                style: {
                    color: 'var(--color-edit-txt)',
                    font: '11px Trebuchet MS, Verdana, sans-serif'
                }
            },
            title: {
                style: {
                    // color: '#333',
                    // fontWeight: 'bold',
                    fontSize: '12px',
                    fontFamily: 'Trebuchet MS, Verdana, sans-serif'

                }
            }
        },
        yAxis: {
            minorTickInterval: 'auto',
            // lineColor: 'var(--color-edit-txt)',
            lineWidth: 1,
            tickWidth: 1,
            // tickColor: 'var(--color-edit-txt)',
            // gridLineWidth: 1,
            // gridLineColor: 'red',
            labels: {
                style: {
                    color: 'var(--color-edit-txt)',
                    font: '11px Trebuchet MS, Verdana, sans-serif'
                }
            },
            title: {
                style: {
                    // color: '#333',
                    // fontWeight: 'bold',
                    fontSize: '12px',
                    fontFamily: 'Trebuchet MS, Verdana, sans-serif'
                }
            }
        },
        series: []
    };

    public get getOptions(): Highcharts.Options {
        return this.options;
    }
}

type DeviceItemProps = {
    history: H.History;
    current_page: string;
    user_id: string;
    device: Device;
    //device_ref: React.RefObject<HTMLDivElement>;
    onDeviceUpdated: (device: Device) => void;
}

export type DeviceAreaType = 'circle' | 'polygon';

type DeviceItemState = {

    device_modal_ref: React.RefObject<HTMLDivElement>,

    is_fetching: boolean,
    error_loading: boolean,
    is_initial_fetching: boolean,

    range_display_date: string, _r_d_d: boolean,
    current_display_date: string, _c_d_d: boolean,
    selected_date: DateRange,
    show_modal: boolean,

    power: number, _pr: boolean,
    power_off_count: number, _prc: boolean,
    door: number, _dr: boolean,
    door_open_count: number, _drc: boolean,
    light: number, _li: boolean,
    temp_in: number, _ti: boolean,
    temp_out: number, _to: boolean,
    humidity: string, _hm: boolean,
    location: GoogleMapReact.Coords, _loc: boolean,
    last_location_updated: number,
    last_data_date: number,
    is_there_data_to_show: boolean,

    // chart_modal_options: ApexCharts.ApexOptions | null,
    // chart_modal_series: Array<ChartSeries>,

    temp_chart_options: Highcharts.Options;
    light_humi_chart_options: Highcharts.Options;
    power_chart_options: Highcharts.Options;
    door_chart_options: Highcharts.Options;

    //
    input_name: string,
    input_location: string,
    input_default_location: { lat: number, lng: number } | null,
    input_area_type: DeviceAreaType,
    // input_radius: number,
    input_polygon: DevicePolygonPoint[],
    input_circle: DeviceCircle,
    is_updating: boolean,
    updating_error: string | null,
}

export default class DeviceItem extends React.Component<DeviceItemProps, DeviceItemState> {

    private _temp_chart_options = new ChartOps().getOptions;
    private _light_humi_chart_options = new ChartOps().getOptions;
    private _power_chart_options = new ChartOps().getOptions;
    private _door_chart_options = new ChartOps().getOptions;

    private databaseManager: DatabaseManager = new DatabaseManager();
    private didMount: boolean = false;
    private fetch_runnable: any | null;

    private input_circle_radio: React.RefObject<HTMLInputElement> = React.createRef();
    private input_polygon_radio: React.RefObject<HTMLInputElement> = React.createRef();

    constructor(props: DeviceItemProps) {
        super(props);

        if (this._temp_chart_options.title) this._temp_chart_options.title.text = 'Temperature Chart ( °C )';
        this._temp_chart_options.colors = ['#3f3e82', '#1bd111'];
        if (this._temp_chart_options.chart && this._temp_chart_options.chart.events) {
            this._temp_chart_options.chart.events.load = () => this.onChartLoad();
            this._temp_chart_options.chart.events.render = () => this.onChartRendered();
            this._temp_chart_options.chart.events.redraw = () => this.onChartRedraw();
        }
        this._temp_chart_options.series = [
            {
                name: "Inside",
                type: "area",
                turboThreshold: 10000,
                data: []
            },
            {
                name: "Outside",
                type: "area",
                turboThreshold: 10000,
                data: []
            }
        ];

        if (this._light_humi_chart_options.title) this._light_humi_chart_options.title.text = 'Light & Humidity Chart ( % )';
        this._light_humi_chart_options.colors = ['#00d0ff', '#e1d862'];
        if (this._light_humi_chart_options.chart && this._light_humi_chart_options.chart.events) {
            this._light_humi_chart_options.chart.events.load = () => this.onChartLoad();
            this._light_humi_chart_options.chart.events.render = () => this.onChartRendered();
            this._light_humi_chart_options.chart.events.redraw = () => this.onChartRedraw();
        }
        this._light_humi_chart_options.series = [
            {
                name: "Light",
                type: "area",
                turboThreshold: 10000,
                data: []
            },
            {
                name: "Humidity",
                type: "area",
                turboThreshold: 10000,
                data: []
            }
        ];

        if (this._power_chart_options.title) this._power_chart_options.title.text = 'Power Chart';
        this._power_chart_options.colors = ['#6a53ff', '#7ef51c'];
        if (this._power_chart_options.chart && this._power_chart_options.chart.events) {
            this._power_chart_options.chart.events.load = () => this.onChartLoad();
            this._power_chart_options.chart.events.render = () => this.onChartRendered();
            this._power_chart_options.chart.events.redraw = () => this.onChartRedraw();
        }
        this._power_chart_options.series = [
            {
                name: "Last State",
                type: "area",
                turboThreshold: 10000,
                data: []
            },
            {
                name: "Off Counts",
                type: "area",
                turboThreshold: 10000,
                data: []
            }
        ];

        if (this._door_chart_options.title) this._door_chart_options.title.text = 'Door Chart';
        this._door_chart_options.colors = ['#3be3e9', '#f5313b'];
        if (this._door_chart_options.chart && this._door_chart_options.chart.events) {
            this._door_chart_options.chart.events.render = () => this.onChartRendered();
            this._door_chart_options.chart.events.load = () => this.onChartLoad();
            this._door_chart_options.chart.events.redraw = () => this.onChartRedraw();
        }
        this._door_chart_options.series = [
            {
                name: "Last State",
                type: "area",
                turboThreshold: 10000,
                data: []
            },
            {
                name: "Open Counts",
                type: "area",
                turboThreshold: 10000,
                data: []
            }
        ];

        this.state = {
            device_modal_ref: React.createRef(),
            is_fetching: false,
            is_initial_fetching: true,
            error_loading: false,

            range_display_date: "", _r_d_d: false,
            current_display_date: "", _c_d_d: false,
            selected_date: [Period.getTodayStartTime(), Period.getTodayEndTime()],
            show_modal: false,

            power: 0, _pr: false,
            power_off_count: 0, _prc: false,
            door: 0, _dr: false,
            door_open_count: 0, _drc: false,
            light: 0, _li: false,
            temp_in: 0, _ti: false,
            temp_out: 0, _to: false,
            humidity: "0", _hm: false,
            location: { lat: 0.0, lng: 0.0 }, _loc: false,
            last_location_updated: 0,
            last_data_date: 0,
            is_there_data_to_show: true,

            temp_chart_options: this._temp_chart_options,
            light_humi_chart_options: this._light_humi_chart_options,
            power_chart_options: this._power_chart_options,
            door_chart_options: this._door_chart_options,

            //
            input_name: '',
            input_location: '',
            input_default_location: null,
            input_area_type: 'circle',
            input_polygon: [],
            input_circle: { radius: NaN },
            is_updating: false,
            updating_error: null,
        }

        this.onSettingSubmit = this.onSettingSubmit.bind(this);

        // if (Highcharts.seriesType && Highcharts.seriesType.scatter)
        //Highcharts.seriesType.scatter.prototype.getPointSpline = Highcharts.seriesType.spline.prototype.getPointSpline;

    }

    componentDidMount() {
        this.didMount = true;
        this.resetData([Period.getTodayStartTime(), Period.getTodayEndTime()], false);
        // window.addEventListener('transitionend', () => {
        //   Highcharts && Highcharts.charts && Highcharts.charts.forEach(chart => {
        //     if (chart) chart.reflow();
        //     console.log('chart.reflow() called')
        //   });
        // });
        // console.log('device: ', this.props.device);
        this.updateDevice(this.props.device);

        this.listenForInputFocus();
    }

    componentWillUnmount() {
        if (this.fetch_runnable) {
            clearInterval(this.fetch_runnable);
        }
        this.clearCharts();
        this.didMount = false;
    }

    componentDidUpdate(prevProps: DeviceItemProps, _prevState: DeviceItemState) {
        if (!this.didMount) {
            return;
        }
        // console.log('DeviceItem.componentDidUpdate: props: ', this.props);
        if (prevProps.device !== this.props.device ||
            prevProps.device.circle !== this.props.device.circle ||
            prevProps.device.polygon !== this.props.device.polygon ||
            prevProps.device.name !== this.props.device.name ||
            prevProps.device.location_name !== this.props.device.location_name ||
            prevProps.device.default_location !== this.props.device.default_location
        ) {
            // console.log('device changed: ', this.props.device);
            this.updateDevice(this.props.device);
        }
    }

    private updateDevice(device: Device) {
        if (!this.didMount || !device) { return; }
        if (device.name) {
            this.setState({ input_name: device.name });
        }
        if (device.location_name) {
            this.setState({ input_location: device.location_name });
        }
        if (device.default_location && device.default_location.lat && device.default_location.lng) {
            this.setState({ input_default_location: device.default_location });
        }
        if (device.circle && device.circle.radius && device.circle.radius > 0) {
            this.setState({ input_circle: device.circle, input_area_type: 'circle' });
        }
        if (device.polygon && device.polygon.length > 0) {
            this.setState({ input_polygon: device.polygon, input_area_type: 'polygon' });
        }
    }

    private listenForInputFocus() {
        setTimeout(() => {
            if (!this.didMount) {
                return;
            }
            const deviceItem: HTMLDivElement = document.getElementById('device-main-item-' + this.props.device.id) as HTMLDivElement;
            if (deviceItem) {
                // find first input element
                const input_element = deviceItem.querySelector('input') as HTMLInputElement; // deviceItem.querySelector('input[class="rs-picker-toggle-textbox"]') as HTMLInputElement;
                if (input_element) {
                    // detect on focus
                    input_element.addEventListener('focus', () => {
                        if (!this.didMount) {
                            return;
                        }
                        // console.log('input_element.focus()');
                        this.setState({ show_modal: true });
                        this.forceUpdate();

                        setTimeout(() => {
                            if (!this.didMount) {
                                return;
                            }
                            // find element by class name
                            const buttons_container = document.querySelector('.rs-picker-toolbar') as HTMLDivElement;
                            if (buttons_container) {
                                // get all buttons and add event listener for click
                                const buttons = buttons_container.querySelectorAll('button');
                                if (buttons && buttons.length > 0) {
                                    for (let i = 0; i < buttons.length; i++) {
                                        if (!this.didMount) {
                                            return;
                                        }
                                        const button = buttons[i] as HTMLButtonElement;
                                        if (button) {
                                            button.addEventListener('click', () => {
                                                if (!this.didMount) {
                                                    return;
                                                }
                                                // console.log('button.click()');
                                                this.setState({ show_modal: false });
                                                this.forceUpdate();
                                            });
                                        }
                                    }
                                }
                            }
                        }, 1000);

                    });
                }

                // detect ecscape key for this div 
                document.addEventListener('keydown', (e) => {
                    if (!this.didMount) {
                        return;
                    }
                    if (e.keyCode === 27) {
                        console.log('deviceItem.keydown()');
                        if (deviceItem) {
                            if (!this.didMount) {
                                return;
                            }
                            if (this.state.device_modal_ref.current) {
                                // perform click
                                this.state.device_modal_ref.current.click();
                            } else {
                                console.log('modal not found');
                                this.setState({ show_modal: false });
                                this.forceUpdate();
                            }
                        }
                    }
                });
            }
        }, 100);
    }

    private onRefreshClick() {
        if (!this.didMount || this.state.is_fetching) {
            return;
        }
        if (!this.props.history.location.pathname.startsWith(`${Routes.Devices}/${this.props.device.id}`)) {
            return;
        }
        this.watchData(this.state.selected_date);
    }

    private resetData(dateRange: DateRange, fromDatePicker: boolean) {
        if (!this.didMount) return;
        const todayDate: DateRange = [Period.getTodayStartTime(), Period.getTodayEndTime()];
        this.setState({
            last_data_date: 0,
            is_fetching: false,
            //is_initial_fetching: false,
            is_there_data_to_show: true,
            range_display_date: "", _r_d_d: false,
            current_display_date: "", _c_d_d: false,
            power: 0, _pr: false,
            power_off_count: 0, _prc: false,
            door: 0, _dr: false,
            door_open_count: 0, _drc: false,
            light: 0, _li: false,
            temp_in: 0, _ti: false,
            temp_out: 0, _to: false,
            humidity: "0", _hm: false,
            location: { lat: 0.0, lng: 0.0 }, _loc: false,
            //
            selected_date: fromDatePicker ? dateRange : todayDate
        });
        this.forceUpdate();

        if (this.fetch_runnable) clearInterval(this.fetch_runnable); // clear previous intervals
        //this.clearCharts();
        this.databaseManager.cancelRequests();

        setTimeout(() => {
            if (!this.didMount) return;
            if (!this.props.history.location.pathname.startsWith(`${Routes.Devices}/${this.props.device.id}`)) {
                return;
            }
            this.watchData(this.state.selected_date);
            this.fetch_runnable = setInterval(() => {
                if (Period.isToday(this.state.selected_date[1].valueOf())) {
                    // console.log('DeviceItem.fetch_runnable: today: device_id: ', this.props.device.id);
                    this.watchData(this.state.selected_date);
                }
            }, 60000);
        }, 500);
    }

    private watchData(dateRange: DateRange) {
        if (!this.didMount) return;
        if (this.state.is_fetching) return;
        if (!this.props.history.location.pathname.startsWith(`${Routes.Devices}/${this.props.device.id}`)) {
            return;
        }
        if (!Period.isToday(dateRange[1].valueOf())) {
            if (this.fetch_runnable) {
                clearInterval(this.fetch_runnable);
            }
        }
        // console.log('watchData: called: ', dateRange);
        const selected_date: DateRange = dateRange;
        const start_date: Date = selected_date[0] ? selected_date[0] : Period.getTodayStartTime();
        const end_date: Date = selected_date[1] ? selected_date[1] : Period.getTodayEndTime();

        if (this.state.last_data_date === 0) {
            const the_start_date: number = start_date.valueOf() + 1;
            const the_end_date: number = end_date.valueOf() + 1;
            this.getData(the_start_date, the_end_date);
        }
        else {
            if (this.state.last_data_date > 0) {
                const last_data_date: number = this.state.last_data_date + 1;
                const _next_date: number = end_date.valueOf() + 1;
                this.getData(last_data_date, _next_date);
            }
        }
    }

    private getData(start_time: number, end_time: number) {
        if (!this.didMount) return;
        if (!this.props.history.location.pathname.startsWith(`${Routes.Devices}/${this.props.device.id}`)) {
            return;
        }
        if (start_time > 0) {
            this.setState({
                range_display_date: Period.displayRangeDate(start_time, end_time),
                _r_d_d: true,
            });
            this.forceUpdate();
            this.databaseManager.cancelRequests();

            this.setState({ is_fetching: true });
            this.databaseManager
                .getDailyDeviceData(this.props.user_id, this.props.device.id, start_time, end_time)
                .then((result: any) => {
                    if (!this.didMount) return;
                    this.computeData(start_time, end_time, result);
                }).catch((_error: any) => {
                    if (!this.didMount) return;
                    this.setState({ is_fetching: false, is_initial_fetching: false });
                });
        }
    }

    private computeData(_start_time: number, _end_time: number, result: any): void {
        if (!this.didMount) return;
        // console.log("computeData: result: ", result);
        //if (this.state.selected_device_id !== device_id) return;
        this.setState({ is_fetching: false, is_initial_fetching: false });
        if (result && result.success && result.success.data &&
            result.success.data.length > 0 && result.success.data[0].data) {
            if (result.success.data.length > 0) {

                const device = result.success.data[0];

                if (device) {
                    if (device.data) {
                        if (device.data.power) {
                            this.setState({
                                power_off_count: device.data.power.off_count ? (this.state.power_off_count + device.data.power.off_count) : this.state.power_off_count,
                                power: device.data.power.current ? device.data.power.current : 0,
                                _pr: true,
                                _prc: true,
                            });
                        }
                        if (device.data.door) {
                            this.setState({
                                door_open_count: device.data.door.open_count ? (this.state.door_open_count + device.data.door.open_count) : this.state.door_open_count,
                                door: device.data.door.current ? device.data.door.current : 0,
                                _dr: true,
                                _drc: true,
                            });
                        }
                        if (device.data.location) {
                            const lat = device.data.location.latitude ? parseFloat(device.data.location.latitude) : 0;
                            const lng = device.data.location.longitude ? parseFloat(device.data.location.longitude) : 0;
                            if (lat !== 0 && lng !== 0) {
                                this.setState({
                                    location: {
                                        lat: lat,
                                        lng: lng
                                    },
                                    last_location_updated: device.data.location.time,
                                    _loc: true
                                });
                            } else {
                                this.setState({ _loc: true });
                            }
                        }
                        if (device.data.temperature) {
                            const tin = device.data.temperature.inside ? parseFloat(device.data.temperature.inside) : this.state.temp_in;
                            const tout = device.data.temperature.outside ? parseFloat(device.data.temperature.outside) : this.state.temp_out;
                            this.setState({
                                temp_in: tin,
                                temp_out: tout,
                                _ti: true,
                                _to: true,
                            });
                        }
                        this.setState({ light: device.data.light ? device.data.light : this.state.light, _li: true });
                        let _humidity: number = device.data.humidity ? device.data.humidity : parseFloat(this.state.humidity);
                        if (_humidity < 0) {
                            _humidity = 0.00;
                        }
                        else if (_humidity > 100) {
                            _humidity = 100.00;
                        }
                        this.setState({ humidity: _humidity.toString(), _hm: true });
                        this.setState({ last_data_date: device.data.time ? device.data.time : this.state.last_data_date });
                        if (device.data.time) {
                            this.setState({
                                current_display_date: Period.displayLastDate(device.data.time),
                                _c_d_d: true,
                            });
                        }
                        this.forceUpdate();
                        this.getChartData(_start_time, _end_time, 'temperature_data');
                        this.getChartData(_start_time, _end_time, 'light_and_humidity_data');
                        this.getChartData(_start_time, _end_time, 'power_data');
                        this.getChartData(_start_time, _end_time, 'door_data');
                    }
                }
            }
        }
        let there_is_no_any_data =
            this.state.power_off_count === 0 && this.state.power_off_count === 0 &&
            this.state.door === 0 && this.state.door_open_count === 0 &&
            this.state.temp_in === 0 && this.state.temp_in === 0 &&
            this.state.humidity === "0";

        this.setState({ is_there_data_to_show: !there_is_no_any_data });
        if (there_is_no_any_data) {
            this.clearCharts();
        }
        this.listenForInputFocus();
        this.forceUpdate();
    }

    private getChartData(start_time: number, end_time: number, category: string) {
        if (!this.didMount) return;
        this.databaseManager
            .getDailyDeviceChartData(this.props.user_id, this.props.device.id, start_time, end_time, category)
            .then((result: any) => {
                if (!this.didMount) return;
                this.computeChartData(category, result);
            }).catch((error: any) => {
                if (!this.didMount) return;
                // console.log("getChartData: error: ", error);
            });
    }

    private async computeChartData(category: string, result: any) {
        if (!this.didMount) return;

        if (result &&
            result.success &&
            result.success.data &&
            result.success.data.length > 0) {
            if (result.success.data[0].statistics &&
                result.success.data[0].statistics.data &&
                result.success.data[0].statistics.data.length > 0) {
                //if (this.state.selected_device_id !== device_id) return;

                // const worker = new Worker(`../tasks/worker`, { name: 'ChartWorker', type: 'module' });
                // const ChartWorker = Comlink.wrap<ChartWorkerBase>(worker);
                const instance = new ChartWorker();

                if (category === 'power_data') {
                    const _chunk = (new Date()).valueOf();
                    this._power_chart_options = this.state.power_chart_options;
                    const workerRequest: ChartWorkerRequest = {
                        device_id: this.props.device.id,
                        statistics: result.success.data[0].statistics,
                        series: this.state.power_chart_options.series,
                        session_id: _chunk
                    }
                    instance.powerChartWorker(workerRequest)
                        .then((workerResult: ChartWorkerResult) => {
                            // worker.terminate();
                            //if (workerResult.device_id !== this.state.selected_device_id) return;
                            if (workerResult.session_id === _chunk) {
                                this._power_chart_options.series = workerResult.series;
                                this._power_chart_options.series && this._power_chart_options.series.map((series_item) => {
                                    if (series_item.type === 'area' ||
                                        series_item.type === 'line' ||
                                        series_item.type === 'bar' ||
                                        series_item.type === 'column') {
                                        return series_item.turboThreshold = series_item.data ? series_item.data.length : 0;
                                    }
                                    else return series_item;
                                });
                                this.setState({ power_chart_options: this._power_chart_options })
                                window.setTimeout(() => {
                                    // Highcharts && Highcharts.charts && Highcharts.charts.forEach(chart => {
                                    //   if (chart) chart.redraw();
                                    // });
                                    this.forceUpdate();
                                }, this.state.power_chart_options.series ? this.state.power_chart_options.series.length : 1000);
                            }
                        });
                }
                else if (category === 'door_data') {
                    const _chunk = (new Date()).valueOf();
                    this._door_chart_options = this.state.door_chart_options;
                    const workerRequest: ChartWorkerRequest = {
                        device_id: this.props.device.id,
                        statistics: result.success.data[0].statistics,
                        series: this.state.door_chart_options.series,
                        session_id: _chunk
                    }
                    instance.doorChartWorker(workerRequest)
                        .then((workerResult: ChartWorkerResult) => {
                            // worker.terminate();
                            //if (workerResult.device_id !== this.state.selected_device_id) return;
                            if (workerResult.session_id === _chunk) {
                                this._door_chart_options.series = workerResult.series;
                                this._door_chart_options.series && this._door_chart_options.series.map((series_item) => {
                                    if (series_item.type === 'area' ||
                                        series_item.type === 'line' ||
                                        series_item.type === 'bar' ||
                                        series_item.type === 'column') {
                                        return series_item.turboThreshold = series_item.data ? series_item.data.length : 0;
                                    }
                                    else return series_item;
                                });
                                this.setState({ door_chart_options: this._door_chart_options })
                                window.setTimeout(() => {
                                    // Highcharts && Highcharts.charts && Highcharts.charts.forEach(chart => {
                                    //   if (chart) chart.redraw();
                                    // });
                                    this.forceUpdate();
                                }, this.state.door_chart_options.series ? this.state.door_chart_options.series.length : 1000);
                            }
                        });
                }
                else if (category === 'temperature_data') {
                    const _chunk = (new Date()).valueOf();
                    this._temp_chart_options = this.state.temp_chart_options;
                    const workerRequest: ChartWorkerRequest = {
                        device_id: this.props.device.id,
                        statistics: result.success.data[0].statistics,
                        series: this.state.temp_chart_options.series,
                        session_id: _chunk
                    }
                    instance.temperatureChartWorker(workerRequest)
                        .then((workerResult: ChartWorkerResult) => {
                            // worker.terminate();
                            //if (workerResult.device_id !== this.state.selected_device_id) return;
                            if (workerResult.session_id === _chunk) {
                                this._temp_chart_options.series = workerResult.series;
                                this._temp_chart_options.series && this._temp_chart_options.series.map((series_item) => {
                                    if (series_item.type === 'area' ||
                                        series_item.type === 'line' ||
                                        series_item.type === 'bar' ||
                                        series_item.type === 'column') {
                                        return series_item.turboThreshold = series_item.data ? series_item.data.length : 0;
                                    }
                                    else return series_item;
                                });
                                this.setState({ temp_chart_options: this._temp_chart_options })
                                // console.log('chart options: ', this.state.temp_chart_options);
                                window.setTimeout(() => {
                                    // Highcharts && Highcharts.charts && Highcharts.charts.forEach(chart => {
                                    //   if (chart) chart.redraw();
                                    // });
                                    this.forceUpdate();
                                }, this.state.temp_chart_options.series ? this.state.temp_chart_options.series.length : 1000);
                            }
                        });
                }
                else if (category === 'light_and_humidity_data') {
                    const _chunk = (new Date()).valueOf();
                    this._light_humi_chart_options = this.state.light_humi_chart_options;
                    const workerRequest: ChartWorkerRequest = {
                        device_id: this.props.device.id,
                        statistics: result.success.data[0].statistics,
                        series: this.state.light_humi_chart_options.series,
                        session_id: _chunk
                    }
                    instance.lightHumidityChartWorker(workerRequest)
                        .then((workerResult: ChartWorkerResult) => {
                            // worker.terminate();
                            //if (workerResult.device_id !== this.state.selected_device_id) return;
                            if (workerResult.session_id === _chunk) {
                                this._light_humi_chart_options.series = workerResult.series;
                                this._light_humi_chart_options.series && this._light_humi_chart_options.series.map((series_item) => {
                                    if (series_item.type === 'area' ||
                                        series_item.type === 'line' ||
                                        series_item.type === 'bar' ||
                                        series_item.type === 'column') {
                                        return series_item.turboThreshold = series_item.data ? series_item.data.length : 0;
                                    }
                                    else return series_item;
                                });
                                this.setState({ light_humi_chart_options: this._light_humi_chart_options })
                                window.setTimeout(() => {
                                    // Highcharts && Highcharts.charts && Highcharts.charts.forEach(chart => {
                                    //   if (chart) chart.redraw();
                                    // });
                                    this.forceUpdate();
                                }, this.state.light_humi_chart_options.series ? this.state.light_humi_chart_options.series.length : 1000);
                            }
                        });
                }
            }
        }
    }

    private onChartRendered(): void {
        // console.log('onChartRendered: called:');
    }

    private onChartLoad(): void {
        // console.log('onChartLoad:     called:');
    }

    private onChartRedraw(): void {
        // console.log('onChartRedraw:   called:');
    }

    private clearCharts() {
        this._power_chart_options.series && this._power_chart_options.series.map((series_item) => {
            if (series_item.type === 'area' ||
                series_item.type === 'line' ||
                series_item.type === 'bar' ||
                series_item.type === 'column') {
                return series_item.data = [];
            }
            else return series_item;
        });

        this._door_chart_options.series && this._door_chart_options.series.map((series_item) => {
            if (series_item.type === 'area' ||
                series_item.type === 'line' ||
                series_item.type === 'bar' ||
                series_item.type === 'column') {
                return series_item.data = [];
            }
            else return series_item;
        });

        this._temp_chart_options.series && this._temp_chart_options.series.map((series_item) => {
            if (series_item.type === 'area' ||
                series_item.type === 'line' ||
                series_item.type === 'bar' ||
                series_item.type === 'column') {
                return series_item.data = [];
            }
            else return series_item;
        });

        this._light_humi_chart_options.series && this._light_humi_chart_options.series.map((series_item) => {
            if (series_item.type === 'area' ||
                series_item.type === 'line' ||
                series_item.type === 'bar' ||
                series_item.type === 'column') {
                return series_item.data = [];
            }
            else return series_item;
        });

        this.setState({
            temp_chart_options: this._temp_chart_options,
            light_humi_chart_options: this._light_humi_chart_options,
            power_chart_options: this._power_chart_options,
            door_chart_options: this._door_chart_options,
        });
    }

    private datePicker() {
        const lower_date = new Date();
        lower_date.setFullYear(2021, 8, 1);
        lower_date.setHours(0, 0, 0, 0);

        return (
            <DateRangePicker
                format={"dd-MM-yyyy HH:mm:ss"}
                appearance="subtle"
                placeholder="Select date range"
                character="  to  "
                // size="sm"
                defaultValue={this.state.selected_date}
                cleanable={false}
                oneTap={false}
                open={this.state.show_modal}
                disabledDate={
                    combine && combine(before && before(lower_date), afterToday && afterToday())
                }
                style={{
                    backgroundColor: "var(--color-div-bg)"
                }}
                ranges={[
                    {
                        label: 'Yesterday',
                        value: [addDays(Period.getDayStartTime(), -1), addDays(Period.getDayEndTime(), -1)]
                    },
                    {
                        label: 'Today',
                        value: [Period.getTodayStartTime(), Period.getTodayEndTime()]
                    },
                    // {
                    //   label: 'Tomorrow',
                    //   value: [dateFns.addDays(new Date(), 1), dateFns.addDays(new Date(), 1)]
                    // },
                    {
                        label: 'Last 7 days',
                        value: [subDays(Period.getDayStartTime(), 6), Period.getDayEndTime()]
                    }
                ]}
                onOpen={() => {
                    // console.log('date-range-picker: opened');
                    this.setState({ show_modal: true });
                }}
                onChange={(date: DateRange | null) => {
                    if (date === null) {
                        return;
                    }
                    // console.log('date-range-picker: changed: ', date);
                    this.resetData(date, true);
                }}
                onOk={(date: DateRange, event: React.SyntheticEvent<Element, Event>) => {
                    if (date === null) {
                        return;
                    }
                    event.preventDefault();
                    // console.log('date-range-picker: ok: ', date);
                    this.resetData(date, true);
                }}
                onClose={() => {
                    this.setState({ show_modal: false });
                }}
            />
        );
    }

    private onValueChange(type: 'name' | 'location' | 'area-circle' | 'area-polygon' | 'radius', e: any) {
        e.preventDefault();
        if (!this.didMount) return;
        if (type === 'name') {
            this.setState({ input_name: e.target.value });
        }
        else if (type === 'location') {
            this.setState({ input_location: e.target.value });
        }
        else if (type === 'area-circle') {
            this.setState({ input_area_type: 'circle' });
        }
        else if (type === 'area-polygon') {
            this.setState({ input_area_type: 'polygon' });
        }
        else if (type === 'radius') {
            this.setState({ input_circle: { radius: (isNaN(e.target.value) || e.target.value === '') ? NaN : parseFloat(e.target.value) } });
        }
        this.forceUpdate();
    }

    private onSettingSubmit(event: any) {
        event.preventDefault();
        if (!this.didMount) return;

        if (this.state.input_default_location === null) {
            this.setState({ updating_error: 'Please select a default device location.' });
            return;
        }
        if (this.state.input_area_type === 'polygon' && this.state.input_polygon.length < 3) {
            this.setState({ updating_error: 'Please select a valid polygon area.' });
            return;
        }
        let circle = this.state.input_circle;
        if (isNaN(circle.radius)) {
            circle.radius = 0;
        }
        this.setState({ is_updating: true });
        this.databaseManager.updateDevice(
            this.props.user_id,
            this.props.device.id,
            this.state.input_name,
            this.state.input_location,
            this.state.input_default_location,
            circle,
            this.state.input_polygon,
            this.state.input_area_type)
            .then(result => {
                if (!this.didMount) { return; }
                this.setState({ is_updating: false });
                if (result && result.success) {
                    this.setState({ updating_error: 'Device updated.' });
                    setTimeout(() => {
                        if (!this.didMount) { return; }
                        this.setState({ updating_error: null });
                        const updated_device = this.props.device;
                        updated_device.name = this.state.input_name;
                        updated_device.location_name = this.state.input_location;
                        if (this.state.input_default_location) {
                            updated_device.default_location = this.state.input_default_location;
                        }
                        if (this.state.input_area_type === 'circle') {
                            updated_device.circle = circle;
                            updated_device.polygon = [];
                        } else {
                            updated_device.circle = { radius: 0 };
                            updated_device.polygon = this.state.input_polygon;
                        }
                        this.props.onDeviceUpdated(updated_device);
                    }, 1000);
                } else if (result && result.error && result.error.message) {
                    this.setState({ updating_error: result.error.message });
                } else {
                    this.setState({ updating_error: 'An error occured while updating device. Please try again.' });
                }
            })
            .catch(_error => {
                if (!this.didMount) { return; }
                this.setState({ is_updating: false, updating_error: 'An error occured while updating device. Please try again.' });
            });
    }

    render(): React.ReactNode {
        return (
            <>
                {this.props.history.location.pathname.startsWith(`${Routes.Devices}/${this.props.device.id}/settings`) &&
                    <div className='setting-lay'>
                        {/* <div className='setting-modal' /> */}
                        <form className='setting-contents' onSubmit={this.onSettingSubmit}>
                            <div className='setting-title-lay'>
                                <button className='update-btn' type='submit'>Update</button>
                                <span className='setting-text'>Device Settings</span>
                                <FaTimes className='setting-close-btn' title='close' onClick={(e) => {
                                    e.preventDefault();
                                    if (this.props.history.length > 1) {
                                        this.props.history.goBack();
                                    } else {
                                        this.props.history.push(`${Routes.Devices}/${this.props.device.id}`);
                                    }
                                }} />
                            </div>
                            {(this.state.is_updating || this.state.updating_error) &&
                                <div className='setting-modal'>
                                    {this.state.is_updating && <div className='setting-loading def-loading-spinner' />}
                                    {this.state.updating_error &&
                                        <span className='setting-error'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ updating_error: null, is_updating: false });
                                            }}>
                                            {this.state.updating_error}
                                        </span>
                                    }
                                </div>
                            }
                            <div className='form-contents'>
                                <div className='input-field-lay'>
                                    <span className='input-field-text'>Device Name</span>
                                    <input className='name-input input'
                                        name='device_name'
                                        value={this.state.input_name}
                                        onChange={(e) => this.onValueChange('name', e)}
                                        type='text'
                                        required={true}
                                        autoComplete={'off'}
                                        placeholder='Enter device name'
                                    />
                                </div>
                                <div className='input-field-lay'>
                                    <span className='input-field-text'>Location Name</span>
                                    <input className='location-input input'
                                        name='device_location'
                                        value={this.state.input_location}
                                        onChange={(e) => this.onValueChange('location', e)}
                                        type='text'
                                        required={true}
                                        autoComplete={'off'}
                                        placeholder='Enter device location name'
                                    />
                                </div>
                                <div className='area-type'>
                                    <span className='area-type-text'>Device geofence area type</span>
                                    <div className='area-type-item' onClick={(e) => this.onValueChange('area-circle', e)} >
                                        <input
                                            tabIndex={-1}
                                            ref={this.input_circle_radio}
                                            className='check-input'
                                            name='area_input'
                                            type='radio'
                                            style={{ pointerEvents: 'none' }}
                                            readOnly={true}
                                            checked={this.state.input_area_type === 'circle'}
                                            onClick={(e) => this.onValueChange('area-circle', e)}
                                        />
                                        <span className='area-type-text'>Circle</span>
                                    </div>
                                    <div className='area-type-item' onClick={(e) => this.onValueChange('area-polygon', e)} >
                                        <input
                                            tabIndex={-1}
                                            ref={this.input_polygon_radio}
                                            className='check-input'
                                            name='area_input'
                                            type='radio'
                                            style={{ pointerEvents: 'none' }}
                                            readOnly={true}
                                            checked={this.state.input_area_type === 'polygon'}
                                            onClick={(e) => this.onValueChange('area-polygon', e)}
                                        />
                                        <span className='area-type-text'>Polygon</span>
                                    </div>
                                    {this.state.input_area_type === 'circle' &&
                                        <div className='input-radius-lay'>
                                            <span className='input-radius-text'>{`Enter radius in metre (m)`}</span>
                                            <input className='radius-input input'
                                                name='device_area_radius'
                                                value={isNaN(this.state.input_circle.radius) ? '' : this.state.input_circle.radius.toString()}
                                                onChange={(e) => this.onValueChange('radius', e)}
                                                type='number'
                                                min={0}
                                                autoComplete={'off'}
                                                placeholder='Area radius'
                                                required={this.state.input_area_type === 'circle'}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="setting-loc">
                                    <CoolerMap
                                        is_dashboard={false}
                                        is_setting={true}
                                        locations={[]}
                                        is_location_data_loaded={true}
                                        location={this.state.location}
                                        last_location_updated={this.state.last_location_updated}
                                        //
                                        default_center={this.state.input_default_location}
                                        geofence_type={this.state.input_area_type}
                                        circle={this.state.input_circle}
                                        polygon={this.state.input_polygon}
                                        onCircleChnged={(circle) => {
                                            // console.log('onCircleChanged: ', circle);
                                            if (!this.didMount) {
                                                return;
                                            }
                                            this.setState({ input_circle: circle })
                                        }}
                                        onPolygonChnged={(polygon) => {
                                            // console.log('onPolygonChnged: ', polygon);
                                            if (!this.didMount) {
                                                return;
                                            }
                                            this.setState({ input_polygon: polygon })
                                        }}
                                        onCenterChnged={(center) => {
                                            // console.log('onCenterChnged: ', center);
                                            if (!this.didMount) {
                                                return;
                                            }
                                            this.setState({ input_default_location: center })
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                }
                {this.state.show_modal &&
                    <div className='device-modal'
                        // id={'device-modal-' + this.props.device.id}
                        ref={this.state.device_modal_ref}
                        onClick={() => {
                            if (!this.didMount) { return; }
                            this.setState({ show_modal: false });
                        }} />
                }
                <div className='device-item-title-lay'>
                    <BiArrowBack className='device-item-back-btn' onClick={(e) => {
                        e.preventDefault();
                        if (this.props.history.length > 1) {
                            this.props.history.goBack();
                        } else {
                            this.props.history.push(`${Routes.Devices}`);
                        }
                    }} />
                    <span className="device-item-title-text">{this.props.device.name}</span>
                    <div className='refresh-btn' onClick={() => this.onRefreshClick()}>Refresh</div>
                </div>
                <div className='device-item-contents-lay'>

                    <div className="info-lay">
                        <div className="info-contents">
                            {this.state.is_initial_fetching && <div className='device-detail-item-loader loading-shimmer' />}
                            <span className="name-text">Id: {this.props.device.id}</span>
                            <span className="name-text">Location: {this.props.device.location_name}</span>
                            <span className="name-text">
                                <Link
                                    to={`${Routes.Devices}/${this.props.device.id}/settings`}
                                    className="edit-btn">
                                    Settings
                                </Link>
                            </span>
                        </div>
                    </div>
                    <div className="status-lay">
                        <div className="status-contents">
                            {this.state.is_initial_fetching && <div className='device-detail-item-loader loading-shimmer' />}
                            <div className="range-display-lay">
                                <div className="range-display-cont">
                                    {this.datePicker()}
                                </div>
                            </div>
                            <div className="current-display-lay">
                                <p className="info">Last Update:  {this.state.is_there_data_to_show ? (this.state._c_d_d ? this.state.current_display_date : "Loading...") : "No Updates"}</p>
                            </div>
                        </div>
                    </div>
                    <div className='data-lay'
                        style={{ display: (this.state.is_fetching || this.state.is_there_data_to_show) ? 'flex' : 'none' }}>
                        <div className="items-lay-first">
                            <div className="power-cont red-grad">
                                {this.state.is_initial_fetching && <div className='device-detail-item-loader loading-shimmer' />}
                                <i className="fa fa-power-off power-icon item-icon" aria-hidden="true"></i>
                                <p className="power-title item-title">Power</p>
                                <div className="sub-items">
                                    <div className="sub-item-cont">
                                        <p className="sub-item-title">Turned OFF</p>
                                        <p className="sub-item-info">{this.state._prc ? ((this.state.power_off_count > 0 ? this.state.power_off_count : "0") + " time(s)") : "Loading..."}</p>
                                    </div>
                                    <div className="sub-item-cont">
                                        <p className="sub-item-title">{Period.isToday((this.state.selected_date[1] ? this.state.selected_date[1] : Period.getTodayEndTime()).valueOf()) ? "Current" : "Current Was"}</p>
                                        <p className="sub-item-info">{this.state._pr ? (this.state.power === 1 ? "ON" : "OFF") : "Loading..."}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="door-cont blue-grad">
                                {this.state.is_initial_fetching && <div className='device-detail-item-loader loading-shimmer' />}
                                <i className="fa fa-door-open door-icon item-icon" aria-hidden="true"></i>
                                <p className="door-title item-title">Door</p>
                                <div className="sub-items">
                                    <div className="sub-item-cont">
                                        <p className="sub-item-title">Opened</p>
                                        <p className="sub-item-info">{this.state._drc ? ((this.state.door_open_count > 0 ? this.state.door_open_count : "0") + " time(s)") : "Loading..."}</p>
                                    </div>
                                    <div className="sub-item-cont">
                                        <p className="sub-item-title">{Period.isToday((this.state.selected_date[1] ? this.state.selected_date[1] : Period.getTodayEndTime()).valueOf()) ? "Current" : "Current Was"}</p>
                                        <p className="sub-item-info">{this.state._dr ? (this.state.door === 1 ? "OPENED" : "CLOSED") : "Loading..."}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="light-cont purple-grad">
                                {this.state.is_initial_fetching && <div className='device-detail-item-loader loading-shimmer' />}
                                <i className="fa fa-lightbulb light-icon item-icon" aria-hidden="true"></i>
                                <p className="light-title item-title">Light</p>
                                <p className="light-info item-info">{this.state._li ? (this.state.light + " %") : "Loading..."}</p>
                            </div>
                            <div className="humi-cont paleblue-grad">
                                {this.state.is_initial_fetching && <div className='device-detail-item-loader loading-shimmer' />}
                                <svg className="item-icon-svg" aria-hidden="true" width="20px" focusable="false" data-prefix="fas" data-icon="humidity" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path
                                        fill="#fff"
                                        d="M223.9 22.1c-8.7-28.8-53.9-30.1-63.8 0C109.1 179.8 0 222.7 0 333.9 0 432.3 85.9 512 192 512s192-79.7 192-178.1c0-111.7-108.9-153.3-160.1-311.8zM96 288c0-17.7 14.3-32 32-32s32 14.3 32 32-14.3 32-32 32-32-14.3-32-32zm49.5 131.8c-2.8 3.5-7.8 4-11.2 1.2l-12.5-10c-3.4-2.8-4-7.8-1.2-11.2l118-147.5c2.8-3.4 7.8-4 11.2-1.2l12.5 10c3.5 2.8 4 7.8 1.2 11.2l-118 147.5zM256 416c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"
                                    >
                                    </path>
                                </svg>
                                <i className="fa fa-raindrops humi-icon item-icon" aria-hidden="true"></i>
                                <p className="humi-title item-title">Humidity</p>
                                <p className="humi-info item-info">{this.state._hm ? (this.state.humidity + " %") : "Loading..."}</p>
                            </div>
                        </div>
                        <div className="items-lay-second">
                            <div className="temp-cont temp-grad">
                                {this.state.is_initial_fetching && <div className='device-detail-item-loader loading-shimmer' />}
                                <i className="fa fa-temperature-low temp-icon item-icon" aria-hidden="true"></i>
                                <p className="temp-title item-title">Temperature</p>
                                <div className="temp-items">
                                    <div className="temp-n-cont">
                                        <p className="temp-n-title">Inside</p>
                                        <p className="temp-n-info">{this.state._ti ? (this.state.temp_in + " °C") : "Loading..."}</p>
                                    </div>
                                    <div className="temp-n-cont">
                                        <p className="temp-n-title">Outside</p>
                                        <p className="temp-n-info">{this.state._to ? (this.state.temp_out + " °C") : "Loading..."}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="loc-cont">
                                {this.state.is_initial_fetching && <div className='device-detail-item-loader loading-shimmer' />}
                                <CoolerMap
                                    is_dashboard={false}
                                    is_setting={false}
                                    locations={[]}
                                    is_location_data_loaded={this.state._loc}
                                    location={this.state.location}
                                    last_location_updated={this.state.last_location_updated}
                                    //
                                    default_center={this.props.device.default_location}
                                    geofence_type={this.props.device.polygon && this.props.device.polygon.length > 0 ? 'polygon' : 'circle'}
                                    circle={this.props.device.circle}
                                    polygon={this.props.device.polygon}
                                />
                            </div>
                        </div>
                        <div className="items-lay-third">
                            <div className="chart-first-lay">
                                <div className="iot-item-chart temp-chart">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        constructorType={'chart'}
                                        allowChartUpdate={true}
                                        immutable={false}
                                        updateArgs={[true, true, false]}
                                        containerProps={{ className: 'chart-lay', id: 'temp-in-out-item-chart-' + this.props.device.id,/* style: { height: '100px', width: '100%' } */ }}
                                        options={this.state.temp_chart_options}
                                    />
                                </div>
                                <div className="iot-item-chart light-humi-chart">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        constructorType={'chart'}
                                        allowChartUpdate={true}
                                        immutable={false}
                                        updateArgs={[true, true, false]}
                                        containerProps={{ className: 'chart-lay', id: 'light-humi-item-chart-' + this.props.device.id }}
                                        options={this.state.light_humi_chart_options}
                                    />
                                </div>
                            </div>
                            <div className="chart-second-lay">
                                <div className="iot-item-chart power-chart">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        constructorType={'chart'}
                                        allowChartUpdate={true}
                                        immutable={false}
                                        updateArgs={[true, true, false]}
                                        containerProps={{ className: 'chart-lay', id: 'power-item-chart-' + this.props.device.id }}
                                        options={this.state.power_chart_options}
                                    />
                                </div>
                                <div className="iot-item-chart door-chart">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        constructorType={'chart'}
                                        allowChartUpdate={true}
                                        immutable={false}
                                        updateArgs={[true, true, false]}
                                        containerProps={{ className: 'chart-lay', id: 'door-item-chart-' + this.props.device.id }}
                                        options={this.state.door_chart_options}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {!this.state.is_there_data_to_show &&
                        <div className="empty-range-data">
                            <span className="empty-range-message-box">
                                <p><b>There are no data in the range time of {this.state.range_display_date}.</b></p>
                                <p>This occurs because of any of the following;</p>
                                <p><b>(1).</b> Probably the device was turned OFF,</p>
                                <p><b>(2).</b> It's battery backup is running low,</p>
                                <p><b>(3).</b> The device is not working properly.</p>
                                <p></p>
                                <p>Hint. Consider keeping up the power to the device. If neccessary, call the device's support team to check your device.</p>
                            </span>
                        </div>
                    }
                </div>
            </>
        );
    }
}