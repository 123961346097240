import './index.scss';

import * as H from "history";

import { Device, UserData } from "../../models";

import { BiArrowBack } from 'react-icons/bi';
import { Link } from "react-router-dom";
import React from "react";
import { RiDeviceFill } from "react-icons/ri";
import Routes from "../../../utils/routes";
import moment from "moment";

type AccountProps = {
    history: H.History;
    current_page: string;
    user_data: UserData;
    show: boolean;
}

type AccountState = {

}

export default class Account extends React.Component<AccountProps, AccountState> {

    // private didMount: boolean = false;
    private avatar: string = require('../../../assets/images/avatar.png');

    constructor(props: AccountProps) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        // this.didMount = true;
        // sort devices by last_update
        this.props.user_data.devices.sort((a: Device, b: Device) => {
            return moment(b.last_updated).diff(moment(a.last_updated));
        });
    }

    componentDidUpdate() {
        this.props.user_data.devices.sort((a: Device, b: Device) => {
            return moment(b.last_updated).diff(moment(a.last_updated));
        });
    }

    componentWillUnmount() {
        // this.didMount = false;
    }

    render() {
        return (
            <div className='account-main' style={{ display: this.props.show ? 'flex' : 'none' }}>
                <div className='account-title-lay'>
                    <BiArrowBack className='account-back-btn'
                        style={{ display: (this.props.current_page === Routes.Account || this.props.current_page === Routes.Account + '/') ? 'flex' : 'none' }}
                        onClick={(e) => {
                            e.preventDefault();
                            if (this.props.history.length > 1) {
                                this.props.history.goBack();
                            } else {
                                this.props.history.push(`${Routes.Dashboard}`);
                            }
                        }} />
                    <span className="account-title-text">Account</span>
                </div>
                <div className='account-contents-lay'>
                    <div className='account-first-lay'>
                        <div className="account-avatar-cont">
                            <div className="account-avatar-top" />
                            <img src={this.avatar} alt="avatar" className="account-avatar-img" />
                        </div>
                        <div className="account-text-cont">
                            <div className='account-text-lay'>
                                <span className='account-text-name'>{this.props.user_data.name}</span>
                            </div>
                            {this.props.user_data.email &&
                                <div className='account-text-lay'>
                                    <span className='account-text-1'>Email: </span>
                                    <span className='account-text-2'>{this.props.user_data.email}</span>
                                </div>
                            }
                            {this.props.user_data.id &&
                                <div className='account-text-lay'>
                                    <span className='account-text-1'>Id: </span>
                                    <span className='account-text-2'>{this.props.user_data.id}</span>
                                </div>
                            }
                            <div className='account-text-lay'>
                                <span className='account-text-1'>Devices: </span>
                                <span className='account-text-2'>{this.props.user_data.devices.length === 1 ? `1 Device` : `${this.props.user_data.devices.length} Devices`}</span>
                            </div>
                        </div>
                    </div>
                    <div className="account-second-lay">
                        <span className="second-list-title">All Devices</span>
                        <div className="second-list-lay">
                            {this.props.user_data.devices.map((device: Device, index: number) => {
                                return (
                                    <DeviceListItem
                                        key={index}
                                        device={device}
                                    />
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class DeviceListItem extends React.Component<{ device: Device }, {}> {

    render() {
        const last_updated = moment(this.props.device.last_updated).format('DD-MM-YYYY hh:mm:ss a');
        return (
            <div className="device-item-lay">
                <div className="device-item-left">
                    <RiDeviceFill className="device-item-icon" />
                </div>
                <div className='device-item-links-lay'>
                    <Link to={`${Routes.Devices}/${this.props.device.id}`} className="device-item-center">
                        <div className="item-name-lay">
                            <span className="item-name-text-1">Name: </span>
                            <span className="item-name-text-2">{this.props.device.name}</span>
                        </div>
                        <div className="item-name-lay">
                            <span className="item-name-text-1">Location: </span>
                            <span className="item-name-text-2">{this.props.device.location_name}</span>
                        </div>
                        <div className="item-name-lay">
                            <span className="item-name-text-1">Last Updated: </span>
                            <span className="item-name-text-2">{last_updated}</span>
                        </div>
                    </Link>
                    <Link to={`${Routes.Devices}/${this.props.device.id}/settings`} className="device-item-right">
                        Settings
                    </Link>
                </div>
            </div>
        );
    }
}