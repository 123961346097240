import { DeviceCircle, DevicePolygonPoint } from '../components/models';

import { OnResult } from './listeners';
import axios from 'axios';

const host = "https://iot-cooler.imperialinnovations.co.tz/api/v1/";

const api_key = "axiopu45yru54piegh048yruht3wp";

export default class DatabaseManager {

    private cancelToken: any = axios.CancelToken;
    private source: any = this.cancelToken.source();

    public loginUser(email: string, password: string, callback: OnResult<any>): void {
        // window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const request = {
            key: api_key,
            email: email, // admin@iot.cooler
            password: password // admin@iot.cooler
        }

        try {
            const chunk = (new Date()).valueOf();

            axios({
                url: host + 'login?t=' + chunk,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // cancelToken: this.source.token
            })
                .then((result: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    console.error("> axios error: ", err.message);
                    callback.onError(err.message);
                })
                .catch((thrown) => {
                    // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    if (axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    } else {
                        console.error("> axios thrown: ", thrown);
                    }
                });

        } catch (e: any) {
            // window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
            callback.onError(e);
        }
    }

    public getUserDevices(user_id: string, callback: OnResult<any>): void {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const request = {
            key: api_key,
            user_id: user_id,
            action: "all_user_devices"
        }

        try {
            const chunk = (new Date()).valueOf();

            axios({
                url: host + 'select?t=' + chunk,
                method: 'post',
                responseType: 'json',
                data: request,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // 'cache-control': 'no-cache',
                    // 'pragma': 'no-cache'
                },
                cancelToken: this.source.token
            })
                .then((result: any) => {
                    window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    // console.log("daily_device_data: server: response: ", result);
                    callback.onResult(result.data ? result.data : null);
                })
                .catch((err: any) => {
                    window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    console.error("> axios error: ", err.message);
                    callback.onError(err.message);
                })
                .catch((thrown) => {
                    window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                    if (axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    } else {
                        console.error("> axios error: ", thrown);
                    }
                });

        } catch (e: any) {
            window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
            callback.onError(e);
        }
    }

    public cancelRequests() {
        this.source.cancel('Operation canceled by the user.');
        this.source = this.cancelToken.source();
    }

    public getDailyDeviceData(user_id: string, device_id: string, start_time: number, end_time: number): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const request = {
            key: api_key,
            user_id: user_id,
            device_id: device_id,
            action: "daily_device_data",
            start_time: start_time,
            end_time: end_time
        }

        return new Promise<void>((resolve, reject) => {
            try {

                // const _request = JSON.stringify(request);
                const chunk = (new Date()).valueOf();
                const url = host + 'select?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: request,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        // 'cache-control': 'no-cache',
                        // 'pragma': 'no-cache'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public getDailyDeviceChartData(user_id: string, device_id: string, start_time: number, end_time: number, category: string): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const request = {
            key: api_key,
            user_id: user_id,
            device_id: device_id,
            action: "daily_device_data",
            start_time: start_time,
            end_time: end_time,
            category: category,
        }

        return new Promise<void>((resolve, reject) => {
            try {
                const chunk = (new Date()).valueOf();
                const url = host + 'select?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: request,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        // 'cache-control': 'no-cache',
                        // 'pragma': 'no-cache'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public updateDevice(
        user_id: string,
        device_id: string,
        name: string,
        location_name: string,
        default_location: { lat: number, lng: number },
        circle: DeviceCircle,
        polygon: DevicePolygonPoint[],
        area_type: 'circle' | 'polygon',
    ): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const request = {
            key: api_key,
            user_id: user_id,
            device_id: device_id,
            action: "update_device",
            input_name: name,
            input_location_name: location_name,
            input_default_location: JSON.stringify(default_location),
            input_circle: JSON.stringify(circle),
            input_polygon: JSON.stringify(polygon),
            input_area_type: area_type,
        }

        return new Promise<void>((resolve, reject) => {
            try {

                // const _request = JSON.stringify(request);
                const chunk = (new Date()).valueOf();
                const url = host + 'update?t=' + chunk;
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: request,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        // 'cache-control': 'no-cache',
                        // 'pragma': 'no-cache'
                    },
                    // cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    // getLatestDeviceData(device_id: string, callback: OnResult<any>): void {
    //     const request = {
    //         key: api_key,
    //         device_id: device_id,
    //         action: "latest"
    //     }

    //     try {
    //         const _request = JSON.stringify(request);

    //         fetch(host + 'select.php', {
    //                 method: 'POST',
    //                 body: _request,
    //                 headers: { 
    //                     'Accept': 'application/json',
    //                     'Content-Type': 'application/x-www-form-urlencoded',
    //                     // 'cache-control': 'no-cache',
    //                     // 'pragma': 'no-cache'
    //                 },
    //             })
    //             .then(res => res.json())
    //             .then((result: any) => {
    //                 console.log("> result: ", result);
    //                 callback.onResult(result);
    //             })
    //             .catch((err: any) => {
    //                 console.error("> fetch error: ", err.message);
    //                 callback.onError(err.message);
    //             });

    //     } catch (e: any) {
    //         callback.onError(e);
    //     }
    // }

    public getDashboardData(user_id: string, start_time: number, end_time: number): Promise<any> {
        window.dispatchEvent(new CustomEvent('data-loading', { detail: true }));
        const request = {
            key: api_key,
            user_id: user_id,
            action: "dashboard_data",
            start_time: start_time,
            end_time: end_time,
        }

        return new Promise<void>((resolve, reject) => {
            try {

                //  this.source.cancel('Operation canceled by the user.');
                //  this.source = this.cancelToken.source();
                // const _request = JSON.stringify(request);
                //  const chunk = (new Date()).valueOf();
                const url = host + 'select?t=' + (new Date()).valueOf();
                axios({
                    url: url,
                    method: 'post',
                    responseType: 'json',
                    data: request,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        // 'cache-control': 'no-cache',
                        // 'pragma': 'no-cache'
                    },
                    cancelToken: this.source.token
                })
                    // .then(res => res.json())
                    .then((result: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        // console.log("daily_device_data: server: response: ", result);
                        resolve(result.data ? result.data : null);
                    })
                    .catch((err: any) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        console.error("> axios error: ", err.message);
                        reject(err.message);
                    })
                    .catch((thrown) => {
                        window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                        if (axios.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.error("> axios error: ", thrown);
                        }
                    });

            } catch (e: any) {
                window.dispatchEvent(new CustomEvent('data-loaded', { detail: true }));
                console.error(e);
                reject(e);
            }
        });
    }

    public logoutUser(callback: OnResult<any>): void {
        try {
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
        } catch (e: any) {
            callback.onError(e);
        }

        setTimeout(() => {
            callback.onResult(true);
        }, 1000);
    }
}

// var d = new Date();
// d.setHours(0,0,0,0); // previous night

// var d_millis = new Date(d);

// console.log('date: ', d);
// console.log('date: ', d_millis.valueOf());

// const final_code = {
//     id: "574392",
//     pr: 1,
//     lat: -1.23345654,
//     lng: -6.23456787,
//     dr: 0,
//     tin: 10,
//     tout: 30,
//     light: 0,
// }
// try {
//     const data = JSON.stringify(final_code);
//     console.log("data to send: ", data);

//     fetch('https://cors-anywhere.herokuapp.com/https://iot-fridge.imperialinnovations.co.tzadd.php', {
//             method: 'post',
//             body: data,
//             headers: {
//                'Content-Type': 'text/plain',
//                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
//                'Access-Control-Allow-Credentials': 'true',
//                'Access-Control-Allow-Origin': '*',
//                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
//                'cors': '*'
//                },
//         })
//         // .then(res => res.json())
//         .then(result => {
//             console.log("> result: ", result);
//         })
//         .catch(err => {
//             console.error("> fetch error: ", err.message);
//         });
// } catch (e) {
//     console.log(e);
// }


// <!--

// // $query = "SELECT du.sn, du.id, du.user_id, du.date_created, md.location, md.name
// //           FROM device_users du
// // 					INNER JOIN main_devices md
// // 					ON du.id = md.id
// // 					WHERE du.user_id = '$user_id'"

// ----------------------------------------------------------------

// SELECT
// DU.user_id, DU.id, MD.location, MD.name,
// DD.power_state, DD.power_off_count, DD.power_off_times, DD.door_state, DD.door_open_count, DD.door_open_times,
// DD.light, DD.location_lat, DD.location_lng, DD.temperature_in, DD.temperature_out, DD.humidity, DD.time

// FROM device_users DU

// INNER JOIN main_devices MD
// ON DU.id = MD.id

// INNER JOIN devices_data DD
// ON DD.id = MD.id AND DD.time > 1632311162860

// WHERE DU.user_id = 'yUj4sxcug9TszueK6wJcvxYAlgEr6EnN'

// -->