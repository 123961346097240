import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import AuthContext from './service/context';
import Landing from './components/landing';
import Login from './components/login';
import Main from './components/main';
import React from 'react';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <Routes />
    </AuthContext.Provider>
  );
}

export default App;

const Routes = () => {
  const Auth = React.useContext(AuthContext);
  return (
    <BrowserRouter>
      {/* <div className="root-modal" id="root-modal" /> */}
      <Switch>
        <Route exact path='/' render={({ history }) => <Landing history={history} />} />
        <Route path='/login' render={({ history }) => <Login history={history} />} />
        <ProtectedRoute
          path={[
            '/dashboard', '/dashboard/',
            '/devices', '/devices/',
            '/account', '/account/',
            '/activities', '/activities/',
            '/help', '/help/',
          ]}
          auth={Auth.isAuthenticated}
          component={Main} />
      </Switch>
    </BrowserRouter>
  );
}

const ProtectedRoute = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth) {
          const user_id = localStorage.getItem('user_id');
          auth = user_id ? true : false;
        }
        // add storage change event listener
        window.addEventListener('storage', (e) => {
          if (e.key === 'user_id') {
            const user_id = localStorage.getItem('user_id');
            auth = user_id ? true : false;
          }
        });
        return ( auth ? <Component {...props} /> : <Redirect to={{ pathname: '/login',  state: { from: props.location } }} />);
          // <AuthContext.Consumer>
          //   {(context) => {
          //     return (
          //       context.isAuthenticated ? <Component {...props} /> : <Redirect to='/login' />
          //     )
          //   }}
          // </AuthContext.Consumer>
      }}
    />
  )
}
