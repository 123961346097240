import './index.scss';

import { Link } from 'react-router-dom';
import React from "react";
import Routes from '../../../utils/routes';

export default class Header extends React.Component<{
  onNavMenuClick(): void;
  user_name: string | null;
}, {
  loading_count: number;
}> {

  private didMount: boolean = false;

  constructor(props: any) {
    super(props);
    this.state = {
      loading_count: 0
    }
  }

  componentDidMount() {
    this.didMount = true;
    window.addEventListener('data-loading', () => {
      if (!this.didMount) {
        return;
      }
      let loading_count = this.state.loading_count + 1;
      this.setState({ loading_count });
      this.forceUpdate();
    });
    window.addEventListener('data-loaded', () => {
      if (!this.didMount) {
        return;
      }
      let loading_count = this.state.loading_count - 1;
      if (loading_count < 0) {
        loading_count = 0;
      }
      this.setState({ loading_count });
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.didMount = false;
  }


  render() {
    return (
      <div className="header">
        <i className="fas fa-bars menu-btn"
          onClick={(e) => {
            e.preventDefault();
            this.props.onNavMenuClick();
          }}></i>
        <span className="app-title">IoT Cooler System</span>

        <div className='header-right-lay'>
          {this.state.loading_count > 0 &&
            <div className="loading-lay">
              <div className="loading-lay-content def-loading-spinner-sm" />
            </div>
          }

          <Link to={Routes.Account}
            className="user-lay" title={this.props.user_name ? this.props.user_name : "User"}>
            <p className="user-name">{this.props.user_name ? this.props.user_name : "User"}</p>
            <i className="fas fa-user user-icon"></i>
          </Link>

          <div className="pallete-container">
            <label className="switch" title="Change theme">
              <input type="checkbox" className="checkbox" id="theme-btn" />
              <span className="toggle-thumb">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20" style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}>
                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20" style={{ fill: "var(--color-pallete-icon)", padding: "6px" }}>
                  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
                </svg>
              </span>
            </label>
          </div>
        </div>

      </div>
    );
  }
}


export const Footer = () => {

  return (
    <div className="bottom-layer">
      {/* <span className="selector-none">
          &copy; 2021 &nbsp;|&nbsp; 
          <a href="http://imperialinnovations.co.tz" target="_blank" title="http://imperialinnovations.co.tz">Imperial Innovations</a>
          &nbsp; (IMC) Tz &nbsp;|&nbsp; All Rights Reserved
        </span> */}
    </div>
  );
}